import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AuthCode from 'react-auth-code-input';
import apiRequest from '../../utils/api';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AdminLogo } from '../../utils/Images';
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';

const JunoFaVerification = ({ onVerificationSuccess, onClose }) => {
    const navigate = useNavigate();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [error, setError] = useState('');
    const [verificationCode2fa, change2faVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);

    const onVerifyCode = async () => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            setLoading(true);
            const body = {
                email: localStorage.getItem('email'),
                authenticator: false,
                is2faActivated: false,
                user2faToken: verificationCode2fa,
                adminRole: localStorage.getItem('adminRole'),
            };

            const response = await apiRequest('/admin-user-verify-2fa-token', 'POST', body);

            if (response.success) {
                setLoading(false);
                if (response.data.success) {
                    localStorage.setItem('2FAStatus', true);
                    onVerificationSuccess();
                } else {
                    setError('Invalid verification code!');
                }
            } else {
                setError(response.error);
            }
        } else {
            setError('Invalid token! 2FA token must have 6-digit only');
        }
    };

    useEffect(() => {
        if (verificationCode2fa.length === 6) {
            onVerifyCode();
        }
    }, [verificationCode2fa]);

    return (
        <div className="w-[644px] p-8 bg-white rounded-lg">
            <CloseOutlined onClick={onClose} className="float-right cursor-pointer" />
            <img src={`${imageBaseUrl}${AdminLogo}`} className="w-36 mx-auto" />
            <div className="w-[360px] mx-auto">
                <h2 className="mt-7 text-4xl font-semibold text-center text-primary">
                    Two-factor authentification
                </h2>
                <p className="mt-3 text-[#667085] font-medium text-center">
                    Please open your authenticator app and enter the verification code below.
                </p>
            </div>

            <div className="theme-modal-field mt-8">
                <div className="authCode mt-5">
                    <AuthCode
                        containerClassName="authCode-container"
                        onChange={code => {
                            setError('');
                            change2faVerificationCode(code);
                        }}
                    />
                </div>
                {loading && (
                    <div className=" mb-2.5 text-sm text-center flex items-center justify-center">
                        Verifying{' '}
                        <Spin className="ml-2" indicator={<LoadingOutlined spin />} size="small" />
                    </div>
                )}
                {error !== '' && (
                    <div className="text-[#df4444] mb-2.5 text-sm text-center">{error}</div>
                )}
            </div>
        </div>
    );
};

export default JunoFaVerification;
