import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const paymentProvider = localStorage.getItem('paymentProviderId');

    if (typeof window === 'undefined') {
        return '';
    }

    return paymentProvider && paymentProvider.length > 0 ? (
        <Component {...rest} />
    ) : (
        <>
            {localStorage.setItem('redirectAfterLogin', window.location.pathname)}
            <Navigate to="/" />
        </>
    );
};

export default ProtectedRoute;
