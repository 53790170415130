import { Button, Divider, Drawer, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoreOutlined, CloseOutlined } from '@ant-design/icons';
import { navbarItems } from '../../utils/constant';
import TransferModal from '../modals/TransferModal';
import WithdrawalAndTPPFiatModal from '../modals/WithdrawalAndTPPFiatModal';
import apiRequest from '../../utils/api';
import { useParams } from 'react-router-dom';
import { getCurrencySymbol, toFixedTrunc, useCommonFunctions } from '../../utils/common';
import FXFiatModal from '../modals/FXFiatModal';
import DepositFiatModal from '../modals/DepositFiatModal';
import { getBalancesData, getBeneficiaryList } from '../../utils/common';
import Loading from '../common/Loading';
import { Freeze, BalanceHistory, DefrostIcon } from '../../utils/Images';
import ThemeButton from '../common/ThemeButton';
import MerchantTransfer from '../modals/MerchantTransfer';
import { useNavigate } from 'react-router-dom';
import FreezeConfirmationModal from '../modals/FreezeConfirmationModal';

const BalanceDetailsTab = () => {
    const dispatch = useDispatch();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [actionTypeModal, setActionTypeModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [isFrozen, setIsFrozen] = useState(false);
    const [unfreezeBalances, setUnfreezeBalances] = useState([]);
    const [freezeModalOpen, setFreezeModalOpen] = useState(false);
    const {
        clientData,
        balancesData,
        savedBeneficiaryAccounts: beneficiaryList,
    } = useSelector(state => state.account);
    const { getClientFeeSetting } = useCommonFunctions();

    const email = localStorage.getItem('email');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const filteredBalances = balancesData.filter(balance => !balance.isFreeze);
        setUnfreezeBalances(filteredBalances);
        setIsFrozen(balancesData.every(balance => balance.isFreeze));
    }, [balancesData]);

    useEffect(() => {
        getBalancesData(
            dispatch,
            clientData.clientId,
            email,
            clientData.clientEmail,
            setBalanceLoading,
        );
        getBeneficiaryList(dispatch, email, clientData.clientEmail);
    }, []);

    const openModalAction = (modalType, selectedCurrency) => {
        setIsModalOpen(true);
        setOpenModal(modalType);
        fetchFeeSetting(modalType);
        setSelectedCurrency(selectedCurrency);
    };

    const onModalClose = () => {
        setIsModalOpen(false);
        setOpenModal('');
    };

    const DrawerHeader = () => {
        return (
            <div className="flex justify-between items-start">
                <div className="flex items-start gap-2">
                    <img
                        className="w-[40px] h-[40px] rounded-full object-cover"
                        src={`${imageBaseUrl}${selectedCurrency.currencyIcon}`}
                        alt="Avatar"
                    />
                    <div className="">
                        <h1 className="text-lg font-semibold">
                            {getCurrencySymbol(selectedCurrency.currencyShortName)}{' '}
                            {toFixedTrunc(selectedCurrency.balanceAmount)}
                        </h1>
                        <p className="text-base font-normal">{selectedCurrency.currencyFullName}</p>
                    </div>
                </div>
                <CloseOutlined onClick={() => setActionTypeModal(false)} />
            </div>
        );
    };

    const createTransaction = async body => {
        try {
            const response = await apiRequest('/create-client-transaction-merchant', 'POST', body);
            if (response.success) {
                await getBalancesData(
                    dispatch,
                    clientData?.clientId,
                    email,
                    clientData?.clientEmail,
                    setBalanceLoading,
                );
                return { success: true };
            } else {
                message.error(response.error);
                return { error: response.error };
            }
        } catch (error) {
            console.error('Error while creating transaction:', error);
        }
    };

    const openBalanceHistory = shortName => {
        const selectedBalance = balancesData.find(
            balance => balance?.currencyShortName === shortName,
        );
        window.open(
            `/accounts/${id}/balance-history/${selectedBalance?.currencyShortName}`,
            '_blank',
        );
    };

    const fetchFeeSetting = async openModal => {
        if (!openModal) return null;

        const transactionType = openModal === 'Third-party payment' ? 'Payment Out' : openModal;

        const payload = {
            email,
            clientId: id,
            transactionType: transactionType,
            currencyType: 'fiat',
        };

        try {
            await getClientFeeSetting(payload);
        } catch (error) {
            console.error('Error fetching fee setting:', error);
        }
    };

    const handleFreezeCurrency = async (currency, isFreeze) => {
        try {
            const response = await apiRequest('/freeze-client-balances', 'POST', {
                clientId: clientData.clientId,
                currency,
                isFreeze,
                email,
            });
            if (response.success) {
                await getBalancesData(
                    dispatch,
                    clientData.clientId,
                    email,
                    clientData.clientEmail,
                    setBalanceLoading,
                );
            } else {
                message.error(response.error);
            }
            setFreezeModalOpen(false);
            setActionTypeModal(false);
        } catch (error) {
            setFreezeModalOpen(false);
            setActionTypeModal(false);
            console.error('Error while freezing currency:', error);
        }
    };

    const handleFreezeAllCurrencies = async () => {
        try {
            const response = await apiRequest('/freeze-client-balances', 'POST', {
                clientId: clientData.clientId,
                isFreeze: !isFrozen,
                email,
                freezeAll: true,
            });
            if (response.success) {
                await getBalancesData(
                    dispatch,
                    clientData.clientId,
                    email,
                    clientData.clientEmail,
                    setBalanceLoading,
                );
            } else {
                message.error(response.error);
            }
            setFreezeModalOpen(false);
        } catch (error) {
            setFreezeModalOpen(false);
            console.error('Error while freezing currency:', error);
        }
    };

    const handleOpenFreezeModal = () => {
        setFreezeModalOpen(true);
    };

    return (
        <div className="md:pt-8 px-6 lg:px-0 bg-white fiat-balance">
            <div className="flex items-center justify-between pt-4 lg:p-0">
                <h1 className="md:text-lg text-base font-semibold">Currencies</h1>
                <div className="font-medium text-sm ">
                    <ThemeButton
                        action={handleOpenFreezeModal}
                        style="mr-3"
                        text={isFrozen ? 'Unfreeze All' : 'Freeze All'}
                        icon={
                            <img
                                src={`${imageBaseUrl}${isFrozen ? DefrostIcon : Freeze}`}
                                alt="freeze"
                                className="w-6 h-6 inline"
                            />
                        }
                    />
                </div>
            </div>
            <Divider className="hidden lg:block my-6" />
            <div className="pt-6 lg:p-0 h-fit fiat-balance-details">
                {balanceLoading ? (
                    <div className="flex flex-col gap-5">
                        <Loading />
                    </div>
                ) : (
                    balancesData.map(balance => (
                        <>
                            <div
                                className="grid grid-cols-3 md:grid-cols-4 items-center justify-between"
                                data-e2e={balance.currencyShortName}
                                data-e2e-amount={toFixedTrunc(balance.balanceAmount)}
                                key={balance.currencyShortName}>
                                <div className="flex items-center gap-5">
                                    <img
                                        className="w-[30px] h-[30px] rounded-full object-cover"
                                        src={`${imageBaseUrl}${balance.currencyIcon}`}
                                        alt="Avatar"
                                    />
                                    <h1
                                        className={`text-sm font-normal ${
                                            balance.isFreeze ? 'text-gray-300' : ''
                                        }`}>
                                        {getCurrencySymbol(balance.currencyShortName)}{' '}
                                        {toFixedTrunc(balance.balanceAmount)}
                                    </h1>
                                </div>
                                <div className="col-span-3 md:block hidden w-full overflow-scroll hide-scrollbar justify-between fiat-balances">
                                    {navbarItems.map(item => (
                                        <Button
                                            onClick={() => openModalAction(item.type, balance)}
                                            data-e2e={`${item.type} ${getCurrencySymbol(balance.currencyShortName)}`}
                                            className={`py-2 px-3 h-full mr-2 rounded-xl text-sm font-medium ${
                                                balance.isFreeze ||
                                                (item.type === 'FX' && unfreezeBalances.length <= 1)
                                                    ? 'cursor-not-allowed text-gray-400 pointer-events-none'
                                                    : ''
                                            }`}
                                            key={item.type}>
                                            {item.type}
                                        </Button>
                                    ))}
                                    <Button
                                        onClick={() =>
                                            handleFreezeCurrency(
                                                balance.currencyShortName,
                                                !balance.isFreeze,
                                            )
                                        }
                                        className="h-full mr-2 fiat-balance-freez-btn"
                                        icon={
                                            <img
                                                src={`${imageBaseUrl}${balance.isFreeze ? DefrostIcon : Freeze}`}
                                                alt="freeze"
                                                className="w-[16px] h-[16px] inline"
                                            />
                                        }
                                    />
                                    <Button
                                        onClick={() =>
                                            openBalanceHistory(balance.currencyShortName)
                                        }
                                        className="h-full mr-2 fiat-balance-freez-btn"
                                        icon={
                                            <img
                                                src={`${imageBaseUrl}${BalanceHistory}`}
                                                alt="balanceHistory"
                                                className="w-16px h-16px inline"
                                            />
                                        }
                                    />
                                </div>
                                <div className="col-span-2 flex justify-end md:hidden">
                                    <MoreOutlined
                                        className="text-xl"
                                        onClick={() => {
                                            setSelectedCurrency(balance);
                                            setActionTypeModal(true);
                                        }}
                                    />
                                </div>
                            </div>
                            <Divider className="my-2" />
                        </>
                    ))
                )}
            </div>
            <Drawer
                title={<DrawerHeader />}
                closable={false}
                open={actionTypeModal}
                destroyOnClose={true}
                height="60vh"
                onClose={() => setActionTypeModal(false)}
                placement="bottom">
                <div className="transaction-actions transaction-actions-fiat">
                    {navbarItems.map((navItem, index) => (
                        <button
                            className={`transaction-actions-btn ${
                                selectedCurrency.isFreeze ||
                                (navItem.type === 'FX' && unfreezeBalances.length <= 1)
                                    ? 'cursor-not-allowed bg-[#FAFAFA] text-gray-400 pointer-events-none'
                                    : ''
                            }`}
                            key={index}
                            onClick={() => {
                                openModalAction(navItem.type, selectedCurrency);
                                // setActionTypeModal(false);
                            }}>
                            <div
                                className={`transaction-actions-icon ${
                                    selectedCurrency.isFreeze ||
                                    (navItem.type === 'FX' && unfreezeBalances.length <= 1)
                                        ? 'bg-gray-100'
                                        : 'bg-[#e4e4e7]'
                                }`}>
                                <img
                                    src={`${imageBaseUrl}${navItem.icon}`}
                                    alt="transaction-actions-btn"
                                />
                                <div
                                    className={`absolute inset-0 bg-gray-200 rounded-full transition-opacity duration-300 ${
                                        selectedCurrency.isFreeze ||
                                        (navItem.type === 'FX' && unfreezeBalances.length <= 1)
                                            ? 'opacity-70'
                                            : 'opacity-0'
                                    }`}></div>
                            </div>
                            <p
                                className={`transaction-actions-title ${
                                    selectedCurrency.isFreeze ||
                                    (navItem.type === 'FX' && unfreezeBalances.length <= 1)
                                        ? '!text-gray-300'
                                        : ''
                                }`}
                                data-e2e={navItem.type}>
                                {navItem.type}
                            </p>
                        </button>
                    ))}
                    <button
                        className={`transaction-actions-btn`}
                        onClick={() => openBalanceHistory(selectedCurrency?.currencyShortName)}>
                        <div className="transaction-actions-icon">
                            <img
                                className="w-6 h-6"
                                src={`${imageBaseUrl}${BalanceHistory}`}
                                alt="balance-history"
                            />
                        </div>
                        <p className="transaction-actions-title">Balance History</p>
                    </button>
                    <button
                        className={`transaction-actions-btn`}
                        onClick={() =>
                            handleFreezeCurrency(
                                selectedCurrency?.currencyShortName,
                                !selectedCurrency.isFreeze,
                            )
                        }>
                        <div className="transaction-actions-icon">
                            <img
                                className="w-6 h-6"
                                src={`${imageBaseUrl}${selectedCurrency?.isFreeze ? DefrostIcon : Freeze}`}
                                alt="balance-history"
                            />
                        </div>
                        <p className="transaction-actions-title">
                            {selectedCurrency?.isFreeze ? 'Unfreeze' : 'Freeze'}
                        </p>
                    </button>
                </div>
            </Drawer>
            <WithdrawalAndTPPFiatModal
                open={isModalOpen && openModal === 'Withdrawal'}
                confirmTitle={'Withdrawal Confirmation'}
                initialTitle={'Withdrawal'}
                transactionType={'Withdrawal'}
                successProcess={'withdrawn'}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                beneficiaryList={beneficiaryList}
                getBeneficiaryList={getBeneficiaryList}
                onWithdrawalSubmit={body => createTransaction(body)}
            />
            <WithdrawalAndTPPFiatModal
                open={isModalOpen && openModal === 'Third-party payment'}
                confirmTitle={'Third-party Payment Confirmation'}
                initialTitle={'Third-party Payment'}
                transactionType={'Payment Out'}
                successProcess={'sent'}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                beneficiaryList={beneficiaryList}
                getBeneficiaryList={getBeneficiaryList}
                onWithdrawalSubmit={body => createTransaction(body)}
            />
            <TransferModal
                transferModal={isModalOpen && openModal === 'Transfer'}
                client={id}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                selectedCurrencyProp={selectedCurrency}
                onSubmit={body => createTransaction(body)}
            />
            <FXFiatModal
                client={id}
                open={isModalOpen && openModal === 'FX'}
                handleClose={onModalClose}
                selectedCurrency={selectedCurrency}
                onSubmit={body => createTransaction(body)}
            />
            <DepositFiatModal
                client={id}
                open={isModalOpen && openModal === 'Deposit'}
                handleClose={onModalClose}
                selectedCurrency={selectedCurrency}
                onSubmit={body => createTransaction(body)}
            />
            <MerchantTransfer
                transferModal={isModalOpen && openModal === 'Merchant'}
                client={id}
                handleClose={onModalClose}
                balancesData={unfreezeBalances}
                selectedCurrencyProp={selectedCurrency}
                onSubmit={body => createTransaction(body)}
            />
            <FreezeConfirmationModal
                open={freezeModalOpen}
                isFrozen={isFrozen}
                handleClose={() => setFreezeModalOpen(false)}
                freezeAllCurrencies={handleFreezeAllCurrencies}
            />
        </div>
    );
};

export default BalanceDetailsTab;
