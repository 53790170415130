import { Button, message, Modal, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { CryptoSelectModal } from './CryptoSelectModal';
import {
    getCryptoFee,
    getCurrencySymbol,
    handleCryptoAmountValidation,
    handleCryptoExchangeRate,
    removeCommas,
    toFixedTrunc,
    useWindowWidth,
    validateAmount,
} from '../../utils/common';
import { useSelector } from 'react-redux';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    LoadingOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import TransactionResultModal from './TransactionResultModal';
import apiRequest from '../../utils/api';
import CryptoPendingModal from './CryptoPendingModal';
import { useNavigate } from 'react-router-dom';

export const ExchangeCryptoModal = ({ open, handleClose, onSubmit, client }) => {
    const navigate = useNavigate();
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [amountError, setAmountError] = useState('');
    const [exchangeRate, setExchangeRate] = useState(1);
    const { clientData } = useSelector(state => state.account);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const windowWidth = useWindowWidth();
    const email = localStorage.getItem('email');
    const [error, setError] = useState('');
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [exchangeLoading, setExchangeLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [quotationData, setQuotationData] = useState();
    const [transactionFee, setTransactionFee] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [fee, setFee] = useState(0);
    const [formData, setFormData] = useState({
        fromCryptoAmount: null,
        toCryptoAmount: null,
        fromCryptoId: 'ETH',
        toCryptoId: 'BTC',
    });
    const [processingModal, setProcessingModal] = useState({ open: false, msg: '' });

    const fromCryptoIcon = balancesCrypto.find(
        x => x.currencyShortName === formData?.fromCryptoId,
    ).currencyIcon;

    const toCryptoIcon = balancesCrypto.find(
        x => x.currencyShortName === formData?.toCryptoId,
    ).currencyIcon;

    const handleModalClose = () => {
        handleClose();
        setIsConfirmModal(false);
        setFormData({
            fromCryptoAmount: null,
            toCryptoAmount: null,
            fromCryptoId: 'ETH',
            toCryptoId: 'BTC',
        });
        setAmountError('');
    };

    const handleAmountChange = e => {
        if (exchangeRate) {
            let fromCryptoAmount, toCryptoAmount, amountError;
            const name = e.target.name;
            const value = e.target.value;

            if (name === 'fromCryptoAmount') {
                toCryptoAmount = exchangeRate && removeCommas(value) * exchangeRate;
                fromCryptoAmount = value;
                const fromCryptoValidationError = handleCryptoAmountValidation(
                    removeCommas(fromCryptoAmount),
                    formData.fromCryptoId,
                    balancesCrypto,
                );
                const validationAmountError = validateAmount(
                    removeCommas(fromCryptoAmount),
                    0,
                    formData.fromCryptoId,
                    balancesCrypto,
                );
                amountError = fromCryptoValidationError || validationAmountError;
            } else if (name === 'toCryptoAmount') {
                const fixedRate = 1 / exchangeRate;
                fromCryptoAmount = fixedRate && removeCommas(value) * fixedRate;
                toCryptoAmount = value;
                amountError = validateAmount(
                    removeCommas(fromCryptoAmount),
                    0,
                    formData.fromCryptoId,
                    balancesCrypto,
                );
            }
            setFormData({ ...formData, fromCryptoAmount, toCryptoAmount });
            setAmountError(amountError);
        }
    };

    const onCancel = () => {
        handleModalClose();
        setStep(1);
        setFormData({
            fromCryptoAmount: null,
            toCryptoAmount: null,
            fromCryptoId: 'ETH',
            toCryptoId: 'BTC',
        });
    };

    const createTransaction = async () => {
        const blockchain = balancesCrypto.find(
            crypto => crypto.currencyShortName === formData.toCryptoId,
        )?.blockchain;
        try {
            const body = {
                adminRole: 'PaymentProviderAdmin',
                email,
                currencyType: 'crypto',
                type: 'Exchange',
                clientId: clientData?.clientId,
                transactionEmail: localStorage.getItem('email'),
                transactionFee:
                    transactionFee && parseFloat(removeCommas(transactionFee)).toFixed(6),
                transactionDetails: {
                    quotationId: quotationData?.rfq_id,
                    fromCryptoAmount: parseFloat(removeCommas(formData.fromCryptoAmount)).toFixed(
                        6,
                    ),
                    toCryptoAmount: parseFloat(removeCommas(formData.toCryptoAmount)).toFixed(6),
                    fromCryptoId: formData.fromCryptoId,
                    toCryptoId: formData.toCryptoId,
                    blockchain,
                    exchangeRate,
                    quotationPrice: quotationData?.price,
                    fee: fee * 100,
                    totalAmount: parseFloat(totalAmount).toFixed(6),
                },
            };
            setLoading(true);
            const response = await onSubmit(body);
            setLoading(false);
            if (response.success) {
                setStep(3);
            } else if (!response?.error.includes('processing')) {
                setError(response?.error);
                setStep(3);
            } else {
                setProcessingModal({ open: true, msg: response?.error });
            }
        } catch (error) {
            onCancel();
            console.error('Error while creating transaction:', error);
        }
    };

    const handleConfirmationModal = () => {
        if (formData.fromCryptoAmount && formData.toCryptoAmount && !amountError) {
            setIsConfirmModal(true);
            setStep(2);
            handleQuotationAmount();
        }
    };

    useEffect(() => {
        let intervalId = null;
        if (isConfirmModal) {
            intervalId = setInterval(() => {
                handleQuotationAmount();
            }, 10000);
        } else {
            clearInterval(intervalId);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConfirmModal]);

    const handleExchangeRate = async () => {
        setExchangeLoading(true);
        const payload = {
            cryptoList: [formData.toCryptoId],
            cryptoAmount: 1,
            cryptoCurrency: formData.fromCryptoId,
            exchangeConversionType: 'crypto-to-crypto',
        };
        const response = await handleCryptoExchangeRate(payload);
        setExchangeRate(response?.cryptoAmounts[formData.toCryptoId]);
        setExchangeLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [formData.fromCryptoId, formData.toCryptoId, open]);

    const fetchData = async () => {
        if (!formData.fromCryptoId || !open) return;

        if (formData.fromCryptoId) {
            await handleExchangeRate();
        }
        const payload = {
            email: email,
            crypto: formData.fromCryptoId,
            toCurrency: formData.toCryptoId,
            profile: clientData.feeProfile,
            transactionType: 'Exchange',
            clientId: clientData.clientId,
        };
        try {
            const cryptoFee = await getCryptoFee(payload);
            setFee(!cryptoFee?.error ? cryptoFee?.data?.fee / 100 : 0);
        } catch (error) {
            console.error('Failed to fetch crypto fee:', error);
        }
    };

    useEffect(() => {
        const { fromCryptoAmount } = formData;

        if (formData) {
            const parsedFromCryptoAmount =
                fromCryptoAmount && parseFloat(removeCommas(fromCryptoAmount));
            const transactionFee = parsedFromCryptoAmount * parseFloat(fee);
            const totalAmount = parsedFromCryptoAmount + transactionFee;
            setTransactionFee(toFixedTrunc(transactionFee, 2));
            setTotalAmount(totalAmount);
        }
    }, [formData, fee]);

    const handleQuotationAmount = async () => {
        setIsLoading(true);
        const blockchain = balancesCrypto.find(
            crypto => crypto.currencyShortName === formData.fromCryptoId,
        )?.blockchain;
        const payload = {
            email: email,
            userId: clientData?.accountNumber,
            crypto: formData.fromCryptoId,
            blockchain: blockchain,
            cryptoAmount: formData.fromCryptoAmount,
            side: 'Buy',
            fiat: formData.toCryptoId,
        };

        try {
            const response = await apiRequest('/request-for-quotation', 'POST', payload);
            if (response.success) {
                setQuotationData(response?.data?.data);
                if (response?.data?.data?.price) {
                    setFormData({
                        ...formData,
                        toCryptoAmount: toFixedTrunc(
                            formData.fromCryptoAmount * parseFloat(response?.data?.data?.price),
                            6,
                        ),
                    });
                }
            } else {
                message.error(response.error);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error in quotation api:', error);
        }
    };

    const handleCryptoId = (field, value) => {
        const oppositeField = field === 'fromCryptoId' ? 'toCryptoId' : 'fromCryptoId';

        if (formData[oppositeField] === value) {
            setAmountError('From and to cryptoId cannot be the same');
        } else {
            setAmountError('');
        }

        setFormData({
            ...formData,
            [field]: value,
            fromCryptoAmount: '',
            toCryptoAmount: '',
        });
    };

    const handleStatus = () => {
        navigate('/transactions', {
            state: {
                clientId: client?.clientId,
            },
        });
    };
    return (
        <>
            <Modal
                open={open}
                destroyOnClose
                className="transaction-modal"
                title={
                    step === 1 ? (
                        <p className="text-2xl font-semibold relative flex">Exchange</p>
                    ) : step === 2 ? (
                        <div className="flex items-center">
                            <ArrowLeftOutlined
                                className="text-xl mr-[12px] w-6 h-6"
                                onClick={() => {
                                    setStep(1);
                                    setIsConfirmModal(false);
                                }}
                            />
                            <p className="text-2xl font-semibold relative flex">
                                Exchange Confirmation
                            </p>
                        </div>
                    ) : null
                }
                onCancel={onCancel}
                footer={
                    step === 3 ? null : (
                        <div className="flex justify-end w-full">
                            {step === 2 && windowWidth > 639 && (
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                                    Cancel
                                </Button>
                            )}
                            <Button
                                type="primary"
                                loading={step === 2 && loading}
                                disabled={!fee || amountError || (step === 2 && !quotationData)}
                                onClick={async () => {
                                    try {
                                        if (step === 1) {
                                            handleConfirmationModal();
                                        } else {
                                            createTransaction();
                                        }
                                    } catch (error) {
                                        console.error('Validation failed:', error);
                                    }
                                }}
                                className="rounded-full px-8 py-3 w-full sm:!w-[140px] h-[46px]"
                                data-e2e={
                                    step === 1
                                        ? 'Continue'
                                        : `${loading ? 'Processing' : 'Exchange Now'}`
                                }>
                                {step === 1
                                    ? 'Continue'
                                    : `${loading ? 'Processing' : 'Exchange Now'}`}
                            </Button>
                        </div>
                    )
                }
                handleClose={onCancel}>
                {step === 1 ? (
                    <>
                        <div className="mt-12 flex justify-between items-center max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">From:</label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name="fromCryptoAmount"
                                    placeholder="Enter From Amount"
                                    data-e2e="from-field"
                                    onChange={handleAmountChange}
                                    value={formData.fromCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[16px] pl-6"
                                />
                                <CryptoSelectModal
                                    value={formData.fromCryptoId}
                                    onChange={e => handleCryptoId('fromCryptoId', e)}
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="my-5 flex items-center">
                            <div className="flex-1 border-b border-[#E4E4E7]"></div>
                            <div className="w-10 h-10 flex-shrink-0 rounded-full flex border border-[#D1D1D6] flex-col justify-center items-center">
                                <CaretUpOutlined className="-mb-0.5" />
                                <CaretDownOutlined className="-mt-0.5" />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center w-full max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">To:</label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    name="toCryptoAmount"
                                    type="text"
                                    placeholder="Enter To Amount"
                                    data-e2e="to-text-field"
                                    onChange={handleAmountChange}
                                    value={formData.toCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[16px] pl-6"
                                />
                                <CryptoSelectModal
                                    value={formData.toCryptoId}
                                    onChange={e => handleCryptoId('toCryptoId', e)}
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">Rate:</label>
                            <div className="text-base font-normal flex items-center gap-2">
                                {exchangeLoading && (
                                    <div className="animate-spin h-[18px] w-[18px] border-gray border rounded-full !border-t !border-t-black" />
                                )}
                                <span>
                                    1 {formData.fromCryptoId} = {toFixedTrunc(exchangeRate, 2)}{' '}
                                    {formData.toCryptoId}
                                </span>
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">Fee:</label>
                            <span className="text-base font-normal">
                                {toFixedTrunc(fee * 100, 4)}%
                            </span>
                        </div>
                        <div className=" flex justify-between items-center mb-12">
                            <label className="text-base font-normal text-start">Total:</label>
                            <span>
                                {getCurrencySymbol(formData.fromCryptoId)} {totalAmount || 0} (
                                {formData.fromCryptoId})
                            </span>
                        </div>
                        {amountError && (
                            <div className="jncAlert alert alert-danger" role="alert">
                                {amountError}
                            </div>
                        )}
                    </>
                ) : (
                    step === 2 && (
                        <div className="my-12 border rounded-lg p-3">
                            <h1 className="text-base font-semibold">Transaction information:</h1>
                            <div className="mt-5">
                                <p className="text-sm font-normal text-[#51525C] ">From:</p>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={`${imageBaseUrl}${fromCryptoIcon}`}
                                            width={24}
                                            height={24}
                                            alt="cryptoIcon"
                                            className=""
                                        />
                                        <span className="text-xl font-normal text-[#26272B]">
                                            {formData.fromCryptoId}
                                        </span>
                                    </div>
                                    <p className="text-xl font-semibold text-[#26272B] ">
                                        {formData.fromCryptoAmount} ({formData.fromCryptoId})
                                    </p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="">
                                <p className="text-sm font-normal text-[#51525C] ">TO:</p>
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={`${imageBaseUrl}${toCryptoIcon}`}
                                            width={24}
                                            height={24}
                                            alt="cryptoIcon"
                                            className=""
                                        />
                                        <span className="text-xl font-normal text-[#26272B]">
                                            {formData.toCryptoId}
                                        </span>
                                    </div>
                                    <p className="text-xl font-semibold text-[#26272B]">
                                        {formData.toCryptoAmount} ({formData.toCryptoId})
                                    </p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center">
                                <p className="text-base font-normal text-[#51525C] ">Rate:</p>
                                <p className="text-base font-medium text-[#18181B] flex items-center gap-2">
                                    <span className="text-base font-medium text-[#18181B]">
                                        1 {formData.fromCryptoId} ={' '}
                                        {toFixedTrunc(quotationData?.price || exchangeRate, 2)}{' '}
                                        {formData.toCryptoId}
                                    </span>
                                </p>
                            </div>
                            <div className="mt-3 flex justify-between items-center">
                                <p className="text-base font-normal text-[#51525C] ">Fee:</p>
                                <p className="text-base font-medium text-[#18181B]">
                                    {toFixedTrunc(fee * 100, 4)}%
                                </p>
                            </div>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center">
                                <p className="text-base font-normal text-[#51525C] ">Total:</p>
                                <p className="text-base font-medium text-[#18181B]">
                                    <span>
                                        {getCurrencySymbol(formData.fromCryptoId)}{' '}
                                        {totalAmount || 0} ({formData.fromCryptoId})
                                    </span>
                                </p>
                            </div>
                            {!quotationData && !isLoading && (
                                <Tag color="red" className="w-full p-3 mt-5">
                                    Error while fetching quotation
                                </Tag>
                            )}
                        </div>
                    )
                )}
            </Modal>
            <TransactionResultModal
                type={error ? error : 'success'}
                open={step === 3}
                handleClick={onCancel}
                title={error ? 'Error!' : 'Exchange Confirmed!'}
                message={error ? error : 'Has been deposited into your Juno Money wallet.'}>
                {!error && (
                    <>
                        <p className="text-xl font-medium text-primary text-center">
                            {getCurrencySymbol(formData.toCryptoId)} {100 || 0} (
                            {formData.toCryptoId})
                        </p>
                    </>
                )}
            </TransactionResultModal>
            <CryptoPendingModal
                open={processingModal.open}
                onCancel={() => setProcessingModal(false)}
                error={processingModal.msg}
                handleConfirm={() => {
                    handleStatus();
                }}
            />
        </>
    );
};
