import React, { useEffect, useState } from 'react';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import { MaintenanceIcon } from '../../utils/Images';
import { message, Switch } from 'antd';

const Maintenance = ({ tab }) => {
    const email = localStorage.getItem('email');
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const getSettings = async () => {
        setLoading(true);
        const body = {
            email,
        };
        const response = await apiRequest('/get-settings', 'POST', body);
        if (response.success) {
            setData(response.data[0]);
            setLoading(false);
        } else {
            message.error(response.error);
        }
    };

    useEffect(() => {
        getSettings();
    }, []);

    const handleSwitchChange = async checked => {
        setLoading(true);
        const body = {
            email,
            ...data,
            value: checked,
            updatedBy: email,
        };
        const response = await apiRequest('/update-settings', 'POST', body);
        if (response.success) {
            getSettings();
            setLoading(false);
        } else {
            message.error(response.error);
        }
    };

    return (
        <>
            <div className="pt-8  w-full sm:block hidden accounts-page h-full">
                <div className="border border-[#E4E4E7] bg-[#FCFCFC] p-4 rounded-lg mx-6 flex gap-3 items-center">
                    <img className="!w-8 !h-8" src={`${imageBaseUrl}${MaintenanceIcon}`} />
                    <p className="flex-1 font-inter font-medium text-lg">
                        Set application wide “Site under maintenance” holding page.
                    </p>
                    <Switch
                        onChange={handleSwitchChange}
                        checked={data ? data.value : false}
                        loading={loading}
                    />
                </div>
            </div>
            <div className="pt-8  w-full sm:hidden block accounts-page h-full">
                <div className="border border-[#E4E4E7] bg-[#FCFCFC] p-3 rounded-lg mx-3 ">
                    <div className="flex gap-3 items-center justify-between">
                        <img className="!w-7 !h-7" src={`${imageBaseUrl}${MaintenanceIcon}`} />

                        <Switch
                            onChange={handleSwitchChange}
                            checked={data ? data.value : false}
                            loading={loading}
                        />
                    </div>
                    <p className="flex-1 font-inter font-medium text-base mt-1">
                        Set application wide “Site under maintenance” holding page.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Maintenance;
