import React from 'react';
import App from './App.js';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { createRoot } from 'react-dom/client';
import initializeStore from './redux/store.js';
import { ConfigProvider } from 'antd';
const initialState =
    // eslint-disable-next-line no-undef
    window && process.env.NODE_ENV !== 'development'
        ? {
              config: {
                  api_url: window.INITIAL_STATE?.config?.REACT_APP_API_URL,
                  payment_api_url: window.INITIAL_STATE?.config?.REACT_APP_API_URL,
                  image_base_url: window.INITIAL_STATE?.config?.IMAGE_BASE_URL + '/images',
                  adminLogin2FA: window.INITIAL_STATE?.config?.ADMIN_LOGIN_2FA,
                  collapsed: true,
                  CAAS_ADMIN_UI_TRANSACTION_URL:
                      window.INITIAL_STATE?.config?.CAAS_ADMIN_UI_TRANSACTION_URL,
              },
          }
        : {
              config: {
                  api_url: 'http://localhost:8081',
                  payment_api_url: 'http://localhost:8080',
                  image_base_url: 'https://dev.junomoney.org/images',
                  adminLogin2FA: 'false',
                  collapsed: true,
                  CAAS_ADMIN_UI_TRANSACTION_URL:
                      'https://dev1-caas-admin.junomoney.org/transactions',
              },
          };

const store = initializeStore(initialState);
export { store }; // Export the store
const container = document.getElementById('root');
const root = createRoot(container);

const theme = {
    token: { colorPrimary: '#18181B', colorError: '#F04438', fontFamily: 'Inter' },
    components: {
        Switch: {
            colorPrimary: '#12B76A',
        },
        Table: {
            borderColor: '#E4E4E7',
            headerBg: '#F4F4F5',
        },
        Divider: {
            colorSplit: '#E4E4E7',
        },
    },
};
root.render(
    <Provider store={store}>
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <ConfigProvider theme={theme}>
                <App />
            </ConfigProvider>
        </ErrorBoundary>
    </Provider>,
);
