import React from 'react';
import ThemeButton from '../components/common/ThemeButton';
import { useNavigate } from 'react-router-dom';
import { NotFoundFrame } from '../utils/Images';
import { useSelector } from 'react-redux';

const NotFound = () => {
    const navigate = useNavigate();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    return (
        <>
            <div className="not-found">
                <div className="flex flex-col justify-center items-center h-screen text-white">
                    <div className="hidden sm:flex justify-end w-full md:mr-32 sm:mr-[-80px]">
                        <img src={`${imageBaseUrl}${NotFoundFrame}`} alt="Frame" />
                    </div>
                    <p className="sm:text-[180px] text-[120px] text-center text-white font-bold sm:leading-[180px] leading-[100px]">
                        404
                    </p>
                    <p className="text-center text-white/90 sm:text-5xl text-3xl font-medium font-['Inter'] leading-[60px]">
                        Page Not Found
                    </p>
                    <div className="sm:w-[522px] w-[90vw] mt-6 mx-6 sm:text-xl text-center text-white/75 text-base font-normal font-['Inter'] leading-7">
                        Sorry, the page you’re looking for does not exist or has been moved. Please
                        go back to the Home page.
                    </div>
                    <ThemeButton
                        autoWidth={true}
                        shape="round"
                        style="bg-black bg-opacity-0 text-white mt-6"
                        size="large"
                        text={'Go back Home'}
                        action={() => navigate('/')}
                    />
                    <div className="hidden sm:flex justify-start w-full md:ml-36 sm:ml-[-120px]">
                        <img src={`${imageBaseUrl}${NotFoundFrame}`} alt="Frame" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
