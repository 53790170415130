import { getNotificationStatusComponent, getNotificationPriorityComponent } from '../utils/common';
import moment from 'moment';

export function renderColumns() {
    return [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 240,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: status => <div>{getNotificationStatusComponent(status)}</div>,
            key: 'status',
            width: 150,
        },
        {
            title: 'Priority',
            key: 'priority',
            dataIndex: 'priority',
            width: 150,
            render: priority => getNotificationPriorityComponent(priority),
        },
        {
            title: 'Date',
            key: 'timestamp',
            dataIndex: 'timestamp',
            sorter: true,
            width: 180,
            render: item => <p>{moment(item).format('DD.MM.YYYY HH:mm')}</p>,
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'message',
            render: item => <p>{item}</p>,
        },
    ];
}
