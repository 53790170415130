import { Tooltip } from 'antd';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BTC, Copy, ETH, Info, LTC, USDT } from './Images';
import moment from 'moment';
import apiRequest from './api';
import * as actions from '../redux/actions/accountActions';
import { setNotificationCount } from '../redux/actions/notificationAction';
import CopyIcon from '../components/icons/CopyIcon';
import { statusCode } from './constant';

export const useWindowWidth = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

export function toFixedTrunc(x, n = 2) {
    let xFixed = x || 0;
    if (xFixed.toString().includes('e')) {
        xFixed = xFixed.toLocaleString('fullwide', { useGrouping: false });
    }
    if (xFixed === 0) {
        return xFixed;
    }

    const v = (typeof xFixed === 'string' ? xFixed : xFixed.toString()).split('.');
    if (n <= 0) {
        return v[0];
    }
    let f = v[1] || '';
    let formatV = v[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (f.length > n) {
        return `${formatV}.${f.substr(0, n)}`;
    }
    if (f === '' && (n === 2 || n === 4)) {
        return `${formatV}.${'0'.repeat(n)}`;
    }
    if (f.length === 1) {
        f = f + '0';
    }
    if (f.length == 0) {
        return `${formatV}`;
    } else {
        return `${formatV}.${f}`;
    }
}

export const getBalanceSign = data => {
    const value = data.type;
    switch (value) {
        case 'Deposit':
        case 'Buy':
            return '+ ';
        case 'Withdrawal':
        case 'Payment Out':
        case 'Sell':
        case 'Send':
            return '- ';
        case 'Transfer':
            return data.transferInOut === 'In' ? '+ ' : '- ';
        default:
            return '';
    }
};
export const cryptoCurrencyIcon = {
    BTC,
    USDT,
    LTC,
    ETH,
};
export const getCurrencySymbol = shortName => {
    switch (shortName) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        case 'GBP':
            return '£';
        case 'AUD':
            return 'A$';
        case 'CAD':
            return 'C$';
        case 'AED':
            return 'د.إ';
        case 'CHF':
            return '₣';
        case 'TRY':
            return '₺';
        case 'HKD':
            return 'HK$';
        case 'SGD':
            return 'S$';
        case 'MXN':
            return 'MX$';
        case 'JPY':
            return '¥';
        case 'CNY':
            return 'CN¥';
        case 'THB':
            return '฿';
        case 'BTC':
            return '₿';
        case 'USDC':
            return 'USDC';
        case 'USDT':
            return '₮';
        case 'LTC':
            return 'Ł';
        case 'ETH':
            return 'Ξ';
        case 'DAI':
            return 'DAI';
        case 'TRX':
            return 'TRX';
        case 'ILS':
            return '₪';
        default:
            return '';
    }
};

export const getStatusComponent = (status, row) => {
    const statusStyles = {
        processing: { bg: '#F4F4F5', text: '#3F3F46', label: 'Processing' },
        completed: { bg: '#ECFDF3', text: '#027A48', label: 'Completed' },
        rejected: { bg: '#FFFAEB', text: '#B54708', label: 'Rejected' },
        failed: { bg: '#FEF3F2', text: '#B42318', label: 'Failed' },
        pending: { bg: '#F4F4F5', text: '#3F3F46', label: 'Pending' },
        default: { bg: '#EFF8FF', text: '#175CD3', label: 'Processing' },
    };

    const statusMap = {
        'PrivateClient:CreateTransaction': 'processing',
        'PrivateClient:ClientBalanceUpdated': 'processing',
        'PrivateClient:DepositDetail': 'processing',
        'PrivateClient:FXDetail': 'processing',
        'PrivateClient:PaymentOutDetail': 'processing',
        'PrivateClient:TransactionApprovedBy': 'processing',
        'PrivateClient:TransferDetail': 'processing',
        'PrivateClient:WithdrawalDetail': 'processing',
        'BuyFlow:InitiateRequest': 'processing',
        'BuyFlow:ValidateRequest': 'processing',
        'BuyFlow:FiatUpdateBalance': 'processing',
        'BuyFlow:CryptoUpdateBalance': 'processing',
        'BuyFlow:SubmitCaasBuyRequest': 'processing',
        'BuyFlow:CaasCallbackReceived': 'processing',
        'BuyFlow:Notification': 'processing',
        'SellFlow:InitiateRequest': 'processing',
        'SellFlow:ValidateRequest': 'processing',
        'SellFlow:FiatUpdateBalance': 'processing',
        'SellFlow:CryptoUpdateBalance': 'processing',
        'SellFlow:SubmitCaasSellRequest': 'processing',
        'SellFlow:CaasCallbackReceived': 'processing',
        'SellFlow:Notification': 'processing',
        'SendFlow:InitiateRequest': 'processing',
        'SendFlow:ValidateRequest': 'processing',
        'SendFlow:UpdateCryptoBalance': 'processing',
        'SendFlow:SubmitCaasSendRequest': 'processing',
        'SendFlow:CaasCallbackReceived': 'processing',
        'SendFlow:Notification': 'processing',
        'ReceiveFlow:InitiateRequest': 'processing',
        'ReceiveFlow:CryptoUpdateBalance': 'processing',
        'ExchangeFlow:InitiateRequest': 'processing',
        'ExchangeFlow:ValidateRequest': 'processing',
        'ExchangeFlow:UpdateFromCryptoBalance': 'processing',
        'ExchangeFlow:UpdateToCryptoBalance': 'processing',
        'ExchangeFlow:SubmitCaasExchangeRequest': 'processing',
        'ExchangeFlow:CaasCallbackReceived': 'processing',
        'ExchangeFlow:Notification': 'processing',
        'PrivateClient:TransactionCompleted': 'completed',
        'BuyFlow:BuyCompleted': 'completed',
        'SellFlow:SellCompleted': 'completed',
        'SendFlow:SendCompleted': 'completed',
        'ReceiveFlow:ReceiveCompleted': 'completed',
        'ExchangeFlow:ExchangeCompleted': 'completed',
        'PrivateClient:Rejected': 'rejected',
        'SendFlow:TransactionRejected': 'rejected',
        'ExchangeFlow:ExchangeFailed': 'rejected',
        'ReceiveFlow:TransactionRejected': 'rejected',
        'BuyFlow:TransactionRejected': 'rejected',
        'SellFlow:TransactionRejected': 'rejected',
        'PrivateClient:ErrorEncountered': 'failed',
        'BuyFlow:ErrorEncountered': 'failed',
        'SellFlow:ErrorEncountered': 'failed',
        'SendFlow:ErrorEncountered': 'failed',
        'ReceiveFlow:ErrorEncountered': 'failed',
        'ExchangeFlow:ErrorEncountered': 'failed',
        'PrivateClient:RequestForApproval': 'pending',
        'SendFlow:CaasSendCryptoNotification': 'pending',
    };

    const statusClass = statusMap[status] || 'default';
    const { bg, text, label } = statusStyles[statusClass];

    return (
        <div
            className="flex items-center gap-2 lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px] font-medium"
            style={{ backgroundColor: bg, color: text }}>
            {label}
            {row?.housekeeping && <TransactionInfo row={row} />}
        </div>
    );
};

export const CopyButton = ({ msg }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [text, setText] = useState('copy');

    const handleCopy = () => {
        navigator.clipboard.writeText(msg).then(() => {
            setText('copied!');
        });
        setTimeout(() => {
            setText('copy');
        }, 1000);
    };

    return (
        <Tooltip placement="top" title={text}>
            <span onClick={handleCopy} className="inline-block ml-2 cursor-pointer copy-icon">
                <CopyIcon />
            </span>
        </Tooltip>
    );
};

export const TransactionInfo = ({ row }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const renderHousekeepingChecks = checks => {
        const checkLabels = {
            approvalCheck: 'ApprovalCheck',
            feeValidationCheck: 'FeeValidationCheck',
            ledgerBalanceCheck: 'LedgerBalanceCheck',
            transactionCheck: 'TransactionCheck',
            transactionHistoryCheck: 'TransactionHistoryCheck',
        };

        return (
            checks &&
            Object.entries(checkLabels).map(
                ([key, label]) =>
                    checks[key] && (
                        <div key={key}>
                            <span className="text-base font-medium">{label}:</span>{' '}
                            {JSON.stringify(checks[key])}
                            <br />
                            <br />
                        </div>
                    ),
            )
        );
    };
    const windowWidth = useWindowWidth();

    return (
        <Tooltip
            title={
                <div className="max-w-[520px]">
                    Validation <br />
                    {renderHousekeepingChecks(row?.housekeeping?.checks)}
                </div>
            }
            className="mr-3"
            overlayInnerStyle={{
                maxWidth: '550px',
                overflow: 'auto',
                maxHeight: '500px',
                width: windowWidth > 1024 ? '550px' : '100%',
            }}>
            <img
                src={`${imageBaseUrl}${Info}`}
                alt="info"
                className="w-5 h-5 rounded-full bg-white cursor-pointer"
            />
        </Tooltip>
    );
};

export const formatAmount = amount => {
    //! Please check it, it throws an eslink no undefined warning during git commit :kristi 27.10
    // eslint-disable-next-line
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(amount);
};

export const getDate = filter => {
    const now = moment();

    switch (filter) {
        case '1D':
            return now.subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ');
        case '2W':
            return now.subtract(2, 'week').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        case '3M':
            return now.subtract(3, 'month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        case '6M':
            return now.subtract(6, 'month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        case 'all':
            return 'all';
        default:
            return now;
    }
};

/**
 * Makes an API request to get presigned URL.
 *
 * @param {Object} [payload] - The payload object for presigne URL API.
 * @param {Object} [selectedFile] - Selected file to upload.
 * @param {String} [imgUrl] - Image string.
 * @returns {Promise<String>} This will return the image URL as string.
 */
export const getPresignedUrl = async (payload, selectedFile, imgUrl) => {
    try {
        const res = await apiRequest('/get-client-presigned-url', 'POST', payload);
        const signedUrl = res.data.data[0];
        await fetch(signedUrl, {
            method: 'PUT',
            body: selectedFile,
            headers: {
                'Content-Type': `image/${selectedFile.type}`,
                'cache-control': 'public, max-age=0',
            },
        });

        return imgUrl;
    } catch (err) {
        console.log('Error in getPresignedUrl: ', err);
        throw err;
    }
};

export const getBalancesData = async (dispatch, clientId, email, clientEmail, setLoading) => {
    setLoading(true);
    const request = {
        clientId: clientId,
        email,
        clientEmail: clientEmail,
    };
    const response = await apiRequest('/get-client-balances', 'POST', request);
    if (response.success) {
        dispatch(actions.setBalancesData(response.data));
    }
    setLoading(false);
};

export const getBeneficiaryList = async (dispatch, email, clientEmail) => {
    const response = await apiRequest('/get-client-beneficiary', 'POST', {
        email,
        clientEmail: clientEmail,
    });
    if (response.success) {
        dispatch(actions.setAccountsData(response.data));
    }
};

export const mapCryptoToBlockchain = cryptoShortName => {
    switch (cryptoShortName) {
        case 'BTC':
            return 'Bitcoin';
        case 'ETH':
        case 'USDC':
        case 'USDT':
        case 'Dai':
            return 'Ethereum';
        case 'LTC':
            return 'Litecoin';
        case 'TRC20-USDT':
            return 'Tron';
        default:
            return '';
    }
};
export const numberRule = /^(\d+)((.{1}\d+)?)$/;
export const numberRuleZero = /^(?:0)[0-9]/;

export const handleCryptoAmountValidation = (cryptoAmount, selectedCurrency, balancesCrypto) => {
    const balance = balancesCrypto.find(x => x.currencyShortName === selectedCurrency);

    if (balance) {
        if (cryptoAmount < balance.minTradeAmt || cryptoAmount > balance.maxTradeAmt) {
            return `*Please enter an amount in the range of ${balance.minTradeAmt} to ${balance.maxTradeAmt}.`;
        }
    }
    return '';
};

export function validateAmount(value, fee, selectedCurrency, balancesData) {
    if (selectedCurrency && selectedCurrency[0]) {
        const balance = balancesData.find(
            x =>
                x.currencyShortName ===
                (Array.isArray(selectedCurrency) ? selectedCurrency[0] : selectedCurrency),
        );

        const transactionFee = fee ? (Number(value) * fee.percentFee) / 100 : 0;
        const finalBalance = Number(value) + transactionFee;

        if (!value) {
            return '* Amount field is required';
        }
        if (numberRuleZero.test(value)) {
            return '* Please, fill the amount value correctly';
        }
        if (!numberRule.test(value)) {
            return '* Please, fill the amount value correctly';
        }
        if (Number(value) <= 0) {
            return '* Amount must be greater than 0';
        }
        if (balance.balanceAmount === 0) {
            return '* Please adjust the value as you have an insufficient balance';
        }
        if (balance && finalBalance > balance.balanceAmount) {
            return transactionFee !== 0
                ? '* Please adjust the value to take into account any fees payable for this transaction'
                : '* Please adjust the value as you have an insufficient balance';
        } else {
            return '';
        }
    }
}

export function removeCommas(inputString) {
    return inputString.length > 0 && inputString.replace(/,/g, '');
}

export const getCryptoFee = async feeData => {
    const request = {
        ...feeData,
    };
    const response = await apiRequest('/get-crypto-fee', 'POST', request);
    if (response.success) {
        return response.data;
    } else {
        console.error();
    }
};
export const getCryptoBalances = async (dispatch, id, paymentProviderId, email) => {
    const request = {
        clientId: id,
        paymentProviderId,
        email,
    };
    const response = await apiRequest('/get-crypto-balance', 'POST', request);
    if (response.success) {
        dispatch(actions.setCryptoBalancesData(response.data));
        return response.data;
    } else {
        console.error();
    }
};
export const handleCryptoExchangeRate = async exchangeData => {
    const request = {
        ...exchangeData,
    };
    const response = await apiRequest('/crypto-exchange-rate', 'POST', request);
    if (response.success) {
        return response.data;
    } else {
        console.error();
    }
};

export const useCommonFunctions = () => {
    const email = localStorage.getItem('email');
    const paymentProviderId = localStorage.getItem('paymentProviderId');
    const { clientData } = useSelector(state => state.account);
    const dispatch = useDispatch();

    const getCryptoBalances = async () => {
        const body = {
            clientId: clientData?.clientId,
            paymentProviderId,
            email,
        };
        try {
            const response = await apiRequest('/get-crypto-balance', 'POST', body);

            if (response?.success) {
                if (response?.data) {
                    dispatch(actions.setCryptoBalancesData(response.data));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getUnreadNotificationCount = async (paymentProviderId, email) => {
        const paymentProvider = localStorage.getItem('paymentProviderId');
        if (paymentProvider && paymentProvider.length > 0) {
            const body = {
                paymentProviderId: paymentProviderId || localStorage.getItem('paymentProviderId'),
                email: email || localStorage.getItem('email'),
            };
            try {
                const response = await apiRequest(
                    '/fetch-unread-notifications-count',
                    'POST',
                    body,
                );
                if (response?.success) {
                    if (response?.data) {
                        dispatch(setNotificationCount(response?.data?.count));
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const getClientFeeSetting = async payload => {
        try {
            const response = await apiRequest('/get-client-fee-setting', 'POST', payload);
            dispatch(actions.setClientFeeSetting(response.data));
            return response;
        } catch (error) {
            console.error('Error fetching fee setting:', error);
        }
    };

    return {
        getCryptoBalances,
        getUnreadNotificationCount,
        getClientFeeSetting,
    };
};

export const getClientTransactionStatusMapping = () => {
    const mapping = {};

    Object.entries(statusCode).forEach(([statusKey, codes]) => {
        codes.forEach(code => {
            mapping[code] = statusKey;
        });
    });

    return mapping;
};

export const getInitials = name => {
    return name
        .split(' ')
        .slice(0, 2)
        .map(word => word[0])
        .join('')
        .toUpperCase();
};

export const getCryptoNameBySymbol = shortName => {
    let newShortName = shortName.trim();
    newShortName = newShortName.replace(/^\s*\(\s*(.*?)\s*\)\s*$/, '$1');
    newShortName = newShortName.replace(/\s+/g, ' ');

    switch (newShortName) {
        case 'BTC' || '(BTC)':
            return 'Bitcoin';
        case 'ETH':
            return 'Ethereum';
        case 'DAI':
            return 'DAI';
        case 'USDT':
            return 'Tether';
        case 'USDC':
            return 'USDC';
        case 'TRX':
            return 'Tron';
        case 'LTC':
            return 'Litecoin';
        default:
            return '';
    }
};
const blockExplorersTransactionsLinks = {
    bitcoin: {
        mainnet: 'https://www.blockchain.com/explorer/transactions/btc/',
        testnet: 'https://blockstream.info/testnet/tx/',
    },
    ethereum: {
        mainnet: 'https://etherscan.io/tx/',
        testnet: 'https://sepolia.etherscan.io/tx/',
    },
    litecoin: {
        mainnet: 'https://blockchair.com/litecoin/transaction/',
        testnet: 'https://live.blockcypher.com/ltc-testnet/',
    },
    ripple: {
        mainnet: 'https://xrpscan.com/ledger/',
        testnet: 'https://test.bithomp.com/',
    },
    tron: {
        mainnet: 'https://tronscan.org/#/transaction/',
        testnet: 'https://shasta.tronscan.org/#/transaction/',
    },
};

const blockExplorersAddressesLinks = {
    bitcoin: {
        mainnet: 'https://www.blockchain.com/explorer/addresses/btc/',
        testnet: 'https://blockstream.info/testnet/address/',
    },
    ethereum: {
        mainnet: 'https://etherscan.io/address/',
        testnet: 'https://sepolia.etherscan.io/address/',
    },
    litecoin: {
        mainnet: 'https://blockchair.com/litecoin/address/',
        testnet: 'https://blockchair.com/litecoin/address/',
    },
    ripple: {
        mainnet: 'https://xrpscan.com/account/',
        testnet: 'https://test.bithomp.com/',
    },
    tron: {
        mainnet: 'https://tronscan.org/#/address/',
        testnet: 'https://shasta.tronscan.org/#/address/',
    },
};

export const getExplorerLink = (blockchain, network, hashOrAddress, isHash) => {
    const lowerCaseBlockchain = blockchain && blockchain.toLowerCase();
    const lowerCaseNetwork = network && network.toLowerCase();
    if (!lowerCaseBlockchain || !lowerCaseNetwork) {
        return '';
    }
    let explorerLink = '';
    if (isHash) {
        explorerLink = blockExplorersTransactionsLinks[lowerCaseBlockchain][lowerCaseNetwork];
    } else {
        explorerLink = blockExplorersAddressesLinks[lowerCaseBlockchain][lowerCaseNetwork];
    }
    return `${explorerLink}${hashOrAddress}`;
};

export const addAuditTrail = async body => {
    const response = await apiRequest('/add-audit-trails', 'POST', body);
    return response;
};

export const fetchIpAddress = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        if (data) {
            localStorage.setItem('ip', data.ip);
        }
    } catch (error) {
        console.error('Failed to fetch IP address', error);
    }
};

export const disableFutureDates = current => {
    const today = new Date();
    return current && current.valueOf() > today.setHours(23, 59, 59, 999);
};

export const getNotificationStatusComponent = status => {
    switch (status) {
        case 'read':
            return (
                <div className="bg-[#ECFDF3] w-fit capitalize-first-letter lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px] font-medium text-[#027A48] ">
                    {status}
                </div>
            );
        case 'unread':
            return (
                <div className="bg-[#FFFAEB] w-fit capitalize-first-letter lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px] font-medium text-[#B54708]">
                    {status}
                </div>
            );
    }
};

export const getNotificationPriorityComponent = priority => {
    switch (priority) {
        case 'low':
            return (
                <div className="bg-[#ECFDF3] w-fit capitalize-first-letter lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px] font-medium text-[#027A48] ">
                    {priority}
                </div>
            );
        case 'medium':
            return (
                <div className="bg-[#FFFAEB] w-fit capitalize-first-letter lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px] font-medium text-[#B54708]">
                    {priority}
                </div>
            );
        case 'high':
            return (
                <div className="bg-[#FEF3F2] w-fit capitalize-first-letter lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px] font-medium text-[#B54708]">
                    {priority}
                </div>
            );
        default:
            return (
                <div className="bg-[#f5f5f4] w-fit capitalize-first-letter lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px] font-medium text-[#3F3F46]">
                    {priority}
                </div>
            );
    }
};
