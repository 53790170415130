import React, { useState, useEffect } from 'react';
import { Form, Modal, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import TransactionResultModal from './TransactionResultModal';
import moment from 'moment';
import { getCurrencySymbol, getInitials, toFixedTrunc } from '../../utils/common';
import { useWindowWidth } from '../../utils/common';
import { fiatCurrencies } from '../../utils/constant';
import { FormInput, FormSelectCurrency } from '../forms';
import ThemeButton from '../common/ThemeButton';
import MerchantTransferChooseMerchant from './MerchantTransferChooseMerchant';
import apiRequest from '../../utils/api';
import ImagePlaceholder from '../common/ImagePlaceholder';

const MerchantTransfer = ({
    transferModal,
    handleClose,
    balancesData,
    selectedCurrencyProp,
    client,
    onSubmit,
}) => {
    const now = moment();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientData = useSelector(state => state.account.clientData);
    // const email = localStorage.getItem('email');
    const [transferButton, setTransferButton] = useState('Transfer');
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState('');
    const [transactionDetails, setTransactionDetails] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [merchantList, setMerchantList] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const email = localStorage.getItem('email');

    const handleMerchantSelect = merchant => {
        setSelectedMerchant(merchant);
    };

    const fetchMerchantList = async () => {
        const body = {
            clientId: clientData._id,
            onlyLinked: true,
            email,
        };
        const res = await apiRequest('/fetch-merchant', 'POST', body);
        if (res.success) {
            if (res.data.merchants.length) {
                setMerchantList(res.data.merchants);
            }
        } else {
            message.error(res.error);
        }
    };

    useEffect(() => {
        fetchMerchantList();
    }, [clientData]);

    useEffect(() => {
        if (transferModal) {
            if (selectedCurrencyProp) {
                setSelectedCurrency(
                    fiatCurrencies.find(currency => {
                        return currency.symbol === selectedCurrencyProp?.currencyShortName;
                    }),
                );
                form.setFieldsValue({
                    currency: selectedCurrencyProp?.currencyShortName,
                });
            } else {
                setSelectedCurrency(balancesData[0]);
                form.setFieldsValue({
                    currency: balancesData[0].currencyShortName,
                });
            }
        }
    }, [transferModal]);

    const [form] = Form.useForm();
    const windowWidth = useWindowWidth();
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const getSuccessMessage = () => {
        return (
            <p>
                Has been sent successfully to{' '}
                <span className="font-semibold">{selectedMerchant?.merchantName}</span>
            </p>
        );
    };

    const continueForm = values => {
        setSelectedCurrency(
            fiatCurrencies.find(currency =>
                values.currency
                    ? currency.symbol === values.currency
                    : currency.symbol === selectedCurrency.symbol,
            ),
        );
        setCurrentStep(currentStep + 1);
        setTransactionDetails(form.getFieldsValue());
    };
    const transfer = async () => {
        setTransferButton('Processing');
        const body = {
            client,
            currency: selectedCurrency?.symbol,
            currencyType: 'fiat',
            email: email,
            paymentProvider,
            transactionDetails: {
                ...transactionDetails,
                currency: selectedCurrency['symbol'],
                email: selectedMerchant?.clientId?.clientEmail,
            },
            type: 'Transfer',
            transactionEmail: email,
            transferInfee: selectedMerchant?.transferInFees,
            balance: { balanceAmount: transactionDetails.amount, updated: new Date() },
        };
        const response = await onSubmit(body);
        if (!response?.success) {
            setError(response?.error);
        }
        setTransferButton('Transfer');
        setCurrentStep(currentStep + 1);
        handleClose();
    };

    const completed = () => {
        form.resetFields();
        setCurrentStep(1);
        setError('');
        setSelectedMerchant(null);
    };

    const back = () => {
        setCurrentStep(currentStep - 1);
        setError('');
    };

    const handleTransferModalClose = () => {
        form.resetFields();
        handleClose();
        setCurrentStep(1);
        setError('');
        setSelectedMerchant(null);
    };

    const TransferModal = () => {
        return (
            <div className="h-[100%] max-h-[80vh]">
                <div className="flex items-center">
                    <ArrowLeftOutlined
                        className="text-xl mr-[12px] w-6 h-6"
                        onClick={() => back()}
                    />
                    <p className="lg:text-2xl text-lg font-semibold relative flex">
                        Transfer to Merchant
                    </p>
                </div>
                <Form
                    form={form}
                    labelCol={{ style: { fontSize: '16px' }, flex: '180px' }}
                    colon={false}
                    layout="horizontal"
                    className="modal-form h-[100%] lg:mt-[20px] mt-[36px] px-[7px]"
                    onFinish={continueForm}>
                    <div className="h-[100%] flex flex-col justify-between">
                        <div className="">
                            <FormInput
                                label="Sending to"
                                name="sendingTo"
                                readOnly
                                dataE2e="enter-email-address-field"
                                value={selectedMerchant?.merchantName}
                                initialValue={selectedMerchant?.merchantName}
                                isMerchantTransfer
                                selectedMerchant={selectedMerchant}
                            />
                            <FormSelectCurrency
                                label="Amount:"
                                name="amount"
                                selectName="currency"
                                required
                                rules={[
                                    { required: true, message: 'Please enter an amount' },
                                    {
                                        validator: async (_, value) => {
                                            const numValue = Number(value);
                                            if (isNaN(numValue) || numValue <= 0) {
                                                return Promise.reject(
                                                    new Error('The amount must be greater than 0'),
                                                );
                                            }
                                            const balance = balancesData.find(
                                                currency =>
                                                    currency.currencyShortName ===
                                                    form.getFieldValue('currency'),
                                            );
                                            if (balance?.balanceAmount < numValue) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Please adjust the value as you have an insufficient balance',
                                                    ),
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                placeholder="Enter amount"
                                data-e2e="enter-amount"
                                selectDefaultValue={
                                    selectedCurrency?.symbol || balancesData[0].currencyShortName
                                }
                                options={(balancesData || []).map(data => ({
                                    value: data.currencyShortName,
                                    label: (
                                        <div className="flex items-center">
                                            <img
                                                src={`${imageBaseUrl}${data.currencyIcon}`}
                                                alt={data.currencyShortName}
                                                className="w-5 mr-[10px]"
                                            />
                                            {data.currencyShortName}
                                        </div>
                                    ),
                                }))}
                                onSelectChange={val => {
                                    form.setFieldsValue({
                                        currency: val,
                                    });
                                    form.setFieldsValue({
                                        amount: '',
                                    });
                                }}
                            />
                            <FormInput
                                label="Reference:"
                                name="reference"
                                placeholder="Enter your reference"
                            />
                        </div>
                    </div>
                </Form>
            </div>
        );
    };
    const TransferConfirmationModal = ({ selectedMerchant }) => {
        const initials = getInitials(selectedMerchant?.merchantName);
        return (
            <>
                <div className="flex items-center">
                    <ArrowLeftOutlined
                        className="text-xl mr-[12px] w-6 h-6"
                        onClick={() => back()}
                    />
                    <p className="lg:text-2xl text-lg font-semibold relative flex">
                        Transfer Confirmation
                    </p>
                </div>

                <div className="h-[100%] lg:mt-[40px] mt-[36px] flex flex-col justify-between">
                    <div className="section">
                        <div className="lg:border-2 rounded-md">
                            <p className="text-lg text-[#3F3F46] lg:p-3 hidden lg:block">
                                Transaction information:
                            </p>
                            <p className="text-[#51525C] lg:px-3 text-base lg:text-sm mb-[8px]">
                                Transfer:
                            </p>
                            <div className="flex lg:justify-between items-center mb-4 lg:px-3  text-gray-800">
                                <span className="flex items-center">
                                    <img
                                        src={`${imageBaseUrl}${selectedCurrency.icon}`}
                                        className="w-[24px] h-[24px] rounded-2xl mr-[8px]"
                                        alt={`${selectedCurrency.name} Logo`}
                                    />
                                    <p className="text-xl hidden lg:block">
                                        {selectedCurrency.name}
                                    </p>
                                </span>
                                <p className="text-xl font-semibold">
                                    {getCurrencySymbol(selectedCurrency.symbol)}{' '}
                                    {toFixedTrunc(form.getFieldValue().amount)} (
                                    {selectedCurrency.symbol})
                                </p>
                            </div>
                            <hr />
                            <section className="text-base">
                                <div className="lg:hidden py-3 flex-row space-y-3">
                                    <p className="font-normal">Date:</p>
                                    <p className="lg:p-3 text-base text-gray-900 font-medium">
                                        {now.format('DD.MM.YY HH:mm')}
                                    </p>
                                </div>
                                <hr className="lg:hidden" />
                                <div className="lg:py-0 py-3 flex-row lg:space-y-0 space-y-3 lg:border-t-[1px] border-[#D1D1D6] bg-[#FAFAFA]">
                                    <p className="lg:hidden font-normal">Sending to:</p>
                                    <div className="flex items-center lg:p-3">
                                        {selectedMerchant.merchantLogo ? (
                                            <img
                                                src={selectedMerchant?.merchantLogo}
                                                alt={`${selectedMerchant?.merchantName} Logo`}
                                                className="w-8 h-8 rounded-full"
                                            />
                                        ) : (
                                            <ImagePlaceholder initials={initials} />
                                        )}
                                        <p className="p-3 lg:font-semibold font-medium text-base text-gray-900">
                                            {selectedMerchant?.merchantName || 'N/A'}
                                        </p>
                                    </div>
                                </div>
                                <hr className="lg:mx-3" />
                                <p className="lg:p-3 py-3 text-base lg:flex sm:block lg:text-gray-600 flex-row lg:space-y-0 space-y-3 bg-[#FAFAFA]">
                                    <p className="lg:font-semibold font-normal text-gray-900 mr-1">
                                        Reference:
                                    </p>
                                    <p className="font-medium">
                                        {form.getFieldValue().reference || 'N/A'}
                                    </p>
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderFooter = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="md:mt-[24px] flex ml-auto sm:w-max w-full and-btn-min-width">
                        <ThemeButton
                            shape="round"
                            type="primary"
                            action={() => setCurrentStep(currentStep + 1)}
                            text="Continue"
                            dataE2e="continue-btn"
                            disabled={!selectedMerchant}
                        />
                    </div>
                );
            case 2:
                return (
                    <div className="md:mt-[36px] lg:mt-[48px] mb-0">
                        <div className="grid grid-cols-2 gap-[10px] lg:max-w-[50%] ml-auto">
                            <ThemeButton
                                action={() => {
                                    setCurrentStep(1);
                                    handleTransferModalClose();
                                }}
                                shape="round"
                                text="Cancel"
                            />
                            <ThemeButton
                                shape="round"
                                type="primary"
                                action={() => form.submit()}
                                text="Continue"
                                dataE2e="continue-btn"
                            />
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="md:mt-[36px] lg:mt-[48px] mb-0">
                        <div className="grid grid-cols-2 gap-[10px] lg:max-w-[50%] ml-auto">
                            <ThemeButton
                                action={() => {
                                    setCurrentStep(1);
                                    handleTransferModalClose();
                                }}
                                shape="round"
                                text="Cancel"
                            />
                            <ThemeButton
                                loading={transferButton === 'Processing'}
                                action={transfer}
                                dataE2e={transferButton}
                                text={transferButton}
                                type="primary"
                                shape="round"
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="transfer-modal-container">
            <Modal
                width={windowWidth < 640 ? '100vw' : '570px'}
                zIndex={1001}
                className="transaction-modal"
                open={transferModal}
                onCancel={handleTransferModalClose}
                closable={true}
                footer={renderFooter()}
                centered>
                {currentStep === 1 && (
                    <MerchantTransferChooseMerchant
                        merchantList={merchantList}
                        handleMerchantSelect={handleMerchantSelect}
                        client={client}
                    />
                )}
                {currentStep === 2 && <TransferModal error={error} />}
                {currentStep === 3 && (
                    <TransferConfirmationModal error={error} selectedMerchant={selectedMerchant} />
                )}
            </Modal>
            <TransactionResultModal
                type={error ? error : 'success'}
                open={currentStep === 4}
                handleClick={completed}
                title={error ? 'Error!' : 'Successful!'}
                message={error ? error : getSuccessMessage()}>
                <div className={error ? 'hidden' : 'block'}>
                    {getCurrencySymbol(selectedCurrency?.symbol)}{' '}
                    {`${toFixedTrunc(form.getFieldValue('amount'))} ${selectedCurrency?.symbol}`}
                </div>
            </TransactionResultModal>
        </div>
    );
};

export default MerchantTransfer;
