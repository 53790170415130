import React, { useEffect, useState } from 'react';
import { Button, Divider, Drawer, Form, Spin, Switch, Table } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import CommonModal from '../modals/CommonModal';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '../../utils/Images';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import { FormInput, FormPassword } from '../forms';
import DeleteModal from '../modals/DeleteModal';
import ThemeButton from '../common/ThemeButton';

const AdditionalAccounts = () => {
    const [openAccountsModal, setOpenAccountsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [detailsData, setDetailsData] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [updateEmail, setupdateEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState({ email: '', password: '' });
    const [deleteModal, setDeleteModal] = useState(false);

    const { id } = useParams();
    const email = localStorage.getItem('email');
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientData = useSelector(state => state.account.clientData);
    const [form] = Form.useForm();

    useEffect(() => {
        getClientAssociateUser();
    }, [id]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: row => row || '-',
        },
        {
            title: 'Email',
            dataIndex: 'clientEmail',
            key: 'email',
        },
        {
            title: 'Read Only',
            key: 'readOnly',
            render: row => (
                <Switch value={row.readOnly} onChange={() => handleUpdateUser(row, 'readOnly')} />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: row => (
                <>
                    <Button
                        className="border-0"
                        icon={<img src={`${imageBaseUrl}${Edit}`} alt="" />}
                        onClick={() => handleEdit(row)}></Button>
                    <Button
                        className="border-0"
                        onClick={() => {
                            setDetailsData(row);
                            setDeleteModal(true);
                        }}
                        icon={
                            <img src={`${imageBaseUrl}${Delete}`} data-e2e="delete" alt="" />
                        }></Button>
                </>
            ),
        },
    ];

    const handleCheck = value => {
        setFormData(prevData => ({
            ...prevData,
            readOnly: value,
        }));
        form.setFieldsValue({
            readOnly: value,
        });
    };

    const getClientAssociateUser = async () => {
        let payload = {
            clientId: id,
            paymentProvider: localStorage.getItem('paymentProviderId'),
            email: email,
        };
        setLoading(true);

        try {
            const response = await apiRequest('/get-client-associated-user', 'POST', payload);

            if (response.success && response.data) {
                setData(response.data.clientsData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = row => {
        setEditModal(true);
        setOpenAccountsModal(true);
        setupdateEmail(row.clientEmail);
        setFormData({
            name: row?.name || '',
            clientEmail: row?.clientEmail || '',
            readOnly: row?.readOnly || false,
        });
        form.setFieldsValue({
            name: row?.name,
            clientEmail: row?.clientEmail,
            readOnly: row?.readOnly,
        });
    };

    const handleClose = () => {
        setOpenAccountsModal(false);
        setEditModal(false);
        setFormData({});
        setDetailsModal(false);
        setDetailsData({});
        setDeleteModal(false);
        form.resetFields();
    };

    const handleCreateUser = async () => {
        const formValues = await form.validateFields();

        setLoading(true);
        const payload = {
            email,
            clientId: id,
            parentClient: clientData && clientData._id,
            clientEmail: formValues.clientEmail,
            name: formValues.name,
            password: formValues.password,
            readOnly: formValues.readOnly,
        };

        try {
            const response = await apiRequest('/create-client-associated-user', 'POST', payload);

            if (response.success) {
                handleClose();
                getClientAssociateUser();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateUser = async (userData, type) => {
        let payload = {
            clientId: id,
            paymentProvider: localStorage.getItem('paymentProviderId'),
            email,
            parentClient: clientData && clientData._id,
            type: type === 'readOnly' ? 'update' : type,
        };
        if (type === 'update') {
            const formValues = await form.validateFields();
            payload = {
                ...payload,
                name: formValues.name,
                updateEmail: updateEmail,
                clientEmail: formValues.clientEmail,
                password: formValues.password,
                readOnly: formValues.readOnly,
            };
        } else if (type === 'readOnly') {
            payload = {
                ...payload,
                updateEmail: userData.clientEmail,
                readOnly: !userData.readOnly,
            };
        } else {
            payload = {
                ...payload,
                clientEmail: userData.clientEmail,
            };
        }
        setLoading(true);
        try {
            const response = await apiRequest('/update-client-associated-user', 'POST', payload);

            if (response.success) {
                handleClose();
                getClientAssociateUser();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="lg:p-0 lg:mt-0 bg-white">
            <div className="p-6 pt-4 lg:pt-8 lg:px-0 flex items-center justify-between">
                <h1 className="text-lg font-semibold">Additional account</h1>
                <div className="font-medium text-sm">
                    <ThemeButton
                        type="primary"
                        style="lg:inline-flex hidden"
                        icon={<PlusOutlined />}
                        dataE2e="add-additional-accounts"
                        text="Add additional account"
                        action={() => setOpenAccountsModal(true)}
                    />
                    <ThemeButton
                        style="lg:hidden"
                        type="primary"
                        icon={<PlusOutlined />}
                        dataE2e="add-new"
                        text="Add new"
                        action={() => setOpenAccountsModal(true)}
                    />
                </div>
            </div>
            <div className="hidden lg:block">
                <Table dataSource={data} columns={columns} pagination={false} loading={loading} />
            </div>
            <div className="border-t lg:hidden">
                {data.length > 0 &&
                    data.map(item => (
                        <div
                            className="flex justify-between items-center p-3 cursor-pointer border-b border-b-[#E4E4E7]"
                            key={item.name}>
                            <div className="flex gap-2 items-center">
                                <span className="bg-[#12B76A] inline-block h-2 w-2 rounded-full"></span>
                                <div>
                                    <h1 className="text-sm font-medium pb-1">{item.name || '-'}</h1>
                                    <p className="font-normal text-xs text-darkGray">
                                        {item.clientEmail}
                                    </p>
                                </div>
                            </div>
                            <div className="flex gap-4 items-center">
                                <div className="text-end">
                                    <Switch
                                        value={item.readOnly}
                                        onChange={() => handleUpdateUser(item, 'readOnly')}
                                    />
                                </div>
                                <div className="">
                                    <MoreOutlined
                                        className="!text-lg inline"
                                        onClick={() => {
                                            setDetailsModal(true);
                                            setDetailsData(item);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                {loading && (
                    <div className="text-center my-5">
                        <Spin />
                    </div>
                )}
            </div>
            <CommonModal
                title={editModal ? 'Edit linked account' : 'Add linked account'}
                form={form}
                open={openAccountsModal}
                handleClose={handleClose}
                className={'common-mobile-view'}
                onFinish={editModal ? () => handleUpdateUser(null, 'update') : handleCreateUser}
                footerText={editModal ? 'Save' : 'Link Account'}>
                <Form
                    layout="horizontal"
                    className="mt-10 modal-form px-2.5"
                    form={form}
                    colon={false}>
                    <FormInput
                        label="Name:"
                        name="name"
                        placeholder="Enter name"
                        defaultValue={formData?.name}
                        dataE2e="enter-name"
                        rules={[{ required: true, message: 'Please enter a name' }]}
                    />
                    <FormInput
                        label="Email:"
                        name="clientEmail"
                        placeholder="Enter email"
                        defaultValue={formData?.clientEmail}
                        dataE2e="enter-email"
                        rules={[
                            { required: true, message: 'Please enter an email' },
                            { type: 'email', message: 'Please enter a valid email' },
                        ]}
                    />
                    <FormPassword
                        label="Password:"
                        name="password"
                        placeholder="Create Password"
                        rules={[{ required: !editModal, message: 'Please enter a password' }]}
                        required={true}
                    />
                    <FormPassword
                        label="Confirm Password:"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        required={true}
                        rules={[
                            {
                                required: !editModal,
                                message: 'Please confirm your password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('The two passwords do not match'),
                                    );
                                },
                            }),
                        ]}
                    />
                    <Form.Item
                        name="readOnly"
                        label={
                            <p className="text-sm lg:text-base text-wrap leading-5 text-start overflow-visible">
                                Read Only
                            </p>
                        }
                        valuePropName="checked">
                        <div className="relative w-full sm:w-[320px] mr-auto">
                            <Switch
                                name="readOnly"
                                className="float-end"
                                data-e2e="onOffBtn"
                                defaultChecked={formData?.readOnly}
                                onChange={handleCheck}
                            />
                        </div>
                    </Form.Item>
                    {errorMsg && (
                        <div className="text-red-500 mt-3">
                            {errorMsg.email || errorMsg.password}
                        </div>
                    )}
                </Form>
            </CommonModal>
            <Drawer
                title={false}
                placement="bottom"
                closable={false}
                onClose={() => setDetailsModal(false)}
                height="auto"
                open={detailsModal}
                key="bottom">
                <div className="">
                    <div className="flex justify-between items-center mb-9">
                        <h1 className="text-lg font-semibold">{detailsData.name || '-'}</h1>
                        <p
                            className="text-lg font-semibold cursor-pointer"
                            onClick={() => setDetailsModal(false)}>
                            X
                        </p>
                    </div>
                    <div className="">
                        <h1 className="pb-3 text-base font-normal">Email:</h1>
                        <p className="text-base font-medium">{detailsData.clientEmail || '-'}</p>
                    </div>
                    <Divider className="my-4" />
                    <div className="flex justify-between items-center">
                        <p className="text-base font-normal">Read only:</p>
                        <span
                            className="py-1 px-3 bg-[#ECFDF3] text-[#027A48] rounded-xl font-medium text-sm"
                            data-e2e={detailsData.readOnly ? 'Active' : 'Inactive'}>
                            {detailsData.readOnly ? 'Active' : 'Inactive'}
                        </span>
                    </div>
                    <div className="mt-9 flex justify-evenly">
                        <button
                            className="border-0 rounded-full bg-[#F4F4F5] p-[10px]"
                            onClick={() => handleEdit(detailsData)}>
                            <img src={`${imageBaseUrl}${Edit}`} alt="edit" width={24} height={24} />
                        </button>

                        <button
                            className="border-0 rounded-full bg-[#F4F4F5] p-[10px]"
                            onClick={() => setDeleteModal(true)}>
                            <img
                                src={`${imageBaseUrl}${Delete}`}
                                alt="Delete"
                                width={24}
                                height={24}
                            />
                        </button>
                    </div>
                </div>
            </Drawer>
            <DeleteModal
                open={deleteModal}
                title={'Delete additional account'}
                content={'Are you sure you want to delete the additional account'}
                toDeleteName={detailsData.name}
                toDeleteEmail={detailsData.clientEmail}
                toDelete={''}
                handleClose={() => setDeleteModal(false)}
                handleDelete={() => handleUpdateUser(detailsData, 'delete')}></DeleteModal>
        </div>
    );
};

export default AdditionalAccounts;
