import React, { useState } from 'react';
import { Divider, Tabs, DatePicker, Select } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import Sidebar from '../components/layout/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import SettingsDrawer from '../components/layout/SettingsDrawer';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { setDateFilter } from '../redux/actions/dateFilterAction';
import moment from 'moment';
import { getDate } from '../utils/common';
import AllTransaction from '../components/AllTransaction';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD.MM.YYYY';

const Dashboard = () => {
    const [openSettings, setOpenSettings] = useState(false);
    const [calenderIcon, setCalenderIcon] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [tabKey, setTabKey] = useState('transactions');
    const [checkedTransaction, setCheckedTransaction] = useState();
    const [currencyType, setCurrencyType] = useState('junomoney');

    const now = moment();
    const image_base_url = useSelector(state => state.config.image_base_url);
    const dispatch = useDispatch();
    const dateFilter = useSelector(state => state.dateFilter);

    const items = [
        // {
        //     key: 'accounts',
        //     label: 'Accounts',
        //     children: 'Content of Tab Pane 1',
        // },
        {
            key: 'transactions',
            label: 'Transactions',
            children: (
                <AllTransaction
                    checkedTransaction={checkedTransaction}
                    currencyType={currencyType}
                />
            ),
        },
    ];

    const dateItems = [
        { label: 'All Time', key: 'all' },
        { label: '6M', key: '6M' },
        { label: '3M', key: '3M' },
        { label: '2W', key: '2W' },
        { label: '1D', key: '1D' },
    ];

    const handleDateFilter = item => {
        const formattedDates = getDate(item.key);
        dispatch(
            setDateFilter({
                date: [item.key],
                startDate: formattedDates,
                endDate: now.format('YYYY-MM-DDTHH:mm:ssZ'),
            }),
        );
        setSelectedDateRange(null);
        setCalenderIcon(false);
    };

    const handleDateChange = (dates, dateStrings) => {
        const [start, end] = dateStrings.map(dateString =>
            moment(dateString, dateFormat).format('YYYY-MM-DDTHH:mm:ssZ'),
        );
        dispatch(
            setDateFilter({
                date: ['range'],
                startDate: moment(start).startOf('day').format('YYYY-MM-DD 00:00'),
                endDate: moment(end).endOf('day').format('YYYY-MM-DD 23:59'),
            }),
        );
        setSelectedDateRange(dates);
    };

    const onChangeTab = tab => {
        setTabKey(tab);
    };

    const DashboardHeader = () => {
        const disableFutureDates = current => {
            const today = new Date();
            return current && current.valueOf() > today.setHours(23, 59, 59, 999);
        };

        return (
            <div className="dashboard-header flex border-b border-[#D1D1D6] justify-between">
                <div className="flex items-center gap-8">
                    <p className="main-page-title">Dashboard</p>
                    <div className="border border-red-[#D1D1D6] h-full" />
                    <Select
                        value={currencyType}
                        onChange={val => setCurrencyType(val)}
                        defaultValue="junomoney"
                        className="w-44"
                        options={[
                            { value: 'junomoney', label: 'Juno Money' },
                            { value: 'junox', label: 'Juno X' },
                        ]}
                    />
                </div>
                <div className="flex gap-[12px] items-center">
                    <div className="lg:flex gap-[8px] hidden">
                        {dateItems.map(item => (
                            <div
                                key={item.key}
                                className={`p-[8px] flex items-center justify-center cursor-pointer hover:bg-[#E4E4E7] hover:rounded-lg ${
                                    dateFilter.date.includes(item.key)
                                        ? 'border-b-2 border-primary bg-[#D1D1D6] rounded-lg'
                                        : ''
                                }`}
                                onClick={() => handleDateFilter(item)}>
                                {item.label}
                            </div>
                        ))}
                    </div>
                    <Divider type="vertical" className="hidden lg:flex h-9 bg-[#D1D1D6]" />
                    {!calenderIcon ? (
                        <button
                            onClick={() => setCalenderIcon(true)}
                            className="p-[6px] hover:bg-[#E4E4E7] hover:rounded-lg">
                            <img
                                alt="calendar"
                                src={`${image_base_url}/adminUi/calenderIcon.svg`}
                                className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                            />
                        </button>
                    ) : (
                        <RangePicker
                            value={selectedDateRange}
                            format={dateFormat}
                            variant="filled"
                            suffixIcon={
                                <img
                                    alt="calendar"
                                    src={`${image_base_url}/adminUi/calenderIcon.svg`}
                                    className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                                />
                            }
                            className={`p-[6px] filter-range-picker max-w-[232px] lg:max-w-max max-h-6 lg:max-h-max ${
                                dateFilter.date.includes('range') && 'filter-range-picker-selected'
                            }`}
                            popupClassName="calender-range-picker"
                            onChange={handleDateChange}
                            disabledDate={disableFutureDates}
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <Sidebar>
                <DashboardHeader />
                <div className="dashboard-tabs">
                    <Tabs
                        defaultActiveKey="transactions"
                        tabBarExtraContent={
                            <SettingFilled
                                onClick={() => setOpenSettings(true)}
                                className="text-xl"
                            />
                        }
                        items={items}
                        onChange={onChangeTab}
                    />
                </div>
                <SettingsDrawer
                    open={openSettings}
                    onClose={() => setOpenSettings(false)}
                    setCheckedTransaction={setCheckedTransaction}
                    tabKey={tabKey}
                    currencyType={currencyType}
                />
            </Sidebar>
        </>
    );
};

export default Dashboard;
