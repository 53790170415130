import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Badge, message, Divider, Pagination, Spin } from 'antd';
import apiRequest from '../../utils/api';
import CustomPagination from '../layout/CustomPagination';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { CopyButton } from '../../utils/common';
import { Delete, Edit } from '../../utils/Images';
import WhiteLabelModal from '../modals/WhiteLabelModal';
import DeleteModal from '../modals/DeleteModal';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ThemeButton from '../common/ThemeButton';

const WhiteLabel = ({ tab }) => {
    const apiURL = useSelector(state => state.config.api_url);
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [data, setData] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [initialValues, setInitialValues] = useState({});
    const [toDelete, setToDelete] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [expandedRowKey, setExpandedRowKey] = useState(null);

    useEffect(() => {
        if (tab == '0') {
            getWhiteLabelUsers(1);
        }
    }, [tab]);

    const getWhiteLabelUsers = async page => {
        setLoading(true);
        const body = {
            email,
            filterParams: {
                paymentProvider,
                page,
                limit: 10,
            },
        };
        const response = await apiRequest('/get-all-juno-white-listing', 'POST', body);
        if (response.success) {
            setData(response.data.whitelabelList);
            setPagination(prev => ({
                ...prev,
                total: response.data.totalCount,
                current: page,
            }));
            setLoading(false);
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const { current } = pagination;

        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));

        getWhiteLabelUsers(current);
    };

    const deleteWhiteLabel = async _id => {
        const body = {
            id: _id,
            email,
        };
        const response = await apiRequest('/delete-juno-white-listing', 'POST', body);
        if (response.success) {
            message.success('Deleted Successfully');
        }
        getWhiteLabelUsers(1);
        setDeleteModal(false);
    };

    const handleClose = () => {
        setIsModal(false);
        setModalType('');
    };

    const modalClose = () => {
        setDeleteModal(false);
    };

    const handleExpandRow = key => {
        setExpandedRowKey(expandedRowKey === key ? null : key);
    };
    const handlePagination = page => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
        }));
        getWhiteLabelUsers(page);
    };

    const columns = [
        {
            title: 'White Lable ID',
            dataIndex: 'whitelabelId',
            key: 'whiteLableID',
            width: 200,
        },
        {
            title: 'White Label Name',
            key: 'whiteLabel',
            width: 200,
            render: row => {
                return <div>{row.whitelableName || 'N/A'}</div>;
            },
        },
        {
            title: 'Domain URL',
            dataIndex: 'domainURL',
            key: 'domainURL',
            width: 200,
        },
        {
            title: 'Redirect URL',
            dataIndex: 'redirectURL',
            key: 'redirectURL',
            width: 200,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 200,
        },
        {
            title: 'Primary',
            dataIndex: 'primaryColor',
            key: 'description',
            width: 150,
        },
        {
            title: 'Login URL',
            key: 'loginURL',
            render: row => {
                return (
                    <div className="flex gap-1">
                        <p className="w-60 text-nowrap overflow-hidden text-ellipsis">
                            {`${apiURL}/login/${row.whitelabelId}`}{' '}
                        </p>
                        <CopyButton msg={`${apiURL}/login/${row.whitelabelId}`} />{' '}
                        <ThemeButton
                            style="ml-4"
                            dataE2e={`${row.whitelableName}-add-new-user`}
                            text="Add New Account"
                            autoWidth={true}
                            action={() => {
                                setIsModal(true);
                                setModalType('addUser');
                                setInitialValues(row);
                            }}
                        />
                        <Button
                            className="shrink-0"
                            type="text"
                            onClick={() => {
                                setIsModal(true);
                                setModalType('edit');
                                setInitialValues(row);
                            }}>
                            {<img className="!w-5 !h-5" src={`${imageBaseUrl}${Edit}`} />}
                        </Button>
                        <Button
                            type="text"
                            className="shrink-0"
                            onClick={() => {
                                setDeleteModal(true);
                                setToDelete(row);
                            }}>
                            {<img className="!w-5 !h-5" src={`${imageBaseUrl}${Delete}`} />}
                        </Button>
                    </div>
                );
            },
        },
    ];

    const ExpandedRow = ({ record }) => {
        const expandedRowFields = [
            { label: 'White label ID', value: record.whitelabelId },
            { label: 'White Label', value: record?.whitelableName || 'N/A' },
            { label: 'Domain URL', value: record.domainURL },
            { label: 'Redirect URL', value: record.redirectURL },
            { label: 'Primary colour', value: record.primaryColor },
        ];
        return (
            <div className="p-3 bg-[#FCFCFC] border rounded-lg">
                {expandedRowFields.map(({ label, value }) => (
                    <p
                        key={label}
                        className="flex justify-between items-center font-normal text-xs text-darkGray pb-3">
                        <span>{label}:</span> {value}
                    </p>
                ))}
                <div className="flex flex-col">
                    <p className="flex justify-between items-center font-normal text-xs text-darkGray pb-2">
                        Login URL:
                    </p>
                    <p className="flex justify-between items-center font-normal text-sm text-darkGray">
                        {`${apiURL}/login/${record.whitelabelId}`}
                        <CopyButton msg={`${apiURL}/login/${record.whitelabelId}`} />
                    </p>
                </div>
                <Divider className="my-4" />
                <div className="flex flex-col">
                    <p className="flex justify-between items-center font-normal text-xs text-darkGray pb-2">
                        Description:
                    </p>
                    <p className="flex justify-between items-center font-normal text-sm text-darkGray">
                        {record.description}
                    </p>
                </div>
                <Divider className="my-4" />
                <Space>
                    <Button
                        onClick={() => {
                            setIsModal(true);
                            setModalType('addUser');
                            setInitialValues(record);
                        }}>
                        Add New User
                    </Button>
                    <Button
                        className="px-2 py-3"
                        icon={<img className="svg-red" src={`${imageBaseUrl}${Delete}`} />}
                        onClick={() => {
                            setDeleteModal(true);
                            setToDelete(record);
                        }}>
                        Remove
                    </Button>
                    <Button
                        className="px-2 py-3"
                        icon={<img src={`${imageBaseUrl}${Edit}`} />}
                        onClick={() => {
                            setIsModal(true);
                            setModalType('edit');
                            setInitialValues(record);
                        }}>
                        Edit
                    </Button>
                </Space>
            </div>
        );
    };

    return (
        <>
            <div className="pt-8 lg:block hidden w-full accounts-page">
                <div className="flex items-center justify-between px-6">
                    <h2 className="page-title">White Label</h2>
                    <Space>
                        <ThemeButton
                            type="primary"
                            text="Add White Label"
                            icon={<PlusOutlined />}
                            action={() => {
                                setIsModal(true);
                                setModalType('add');
                                setInitialValues({});
                            }}
                        />
                    </Space>
                </div>
                <Table
                    dataSource={data}
                    columns={columns}
                    className="cursor-pointer rounded-b-lg custom-table mt-6 settings-table"
                    scroll={true}
                    rowKey={record => record._id}
                    loading={loading}
                    pagination={{
                        position: ['bottomCenter'],
                        itemRender: CustomPagination,
                        showSizeChanger: false,
                        current: pagination.current,
                        total: pagination.total,
                    }}
                    onChange={handleTableChange}
                />
            </div>

            <div className="block lg:hidden">
                {!loading ? (
                    <>
                        <h2 className="text-base font-semibold ml-3 my-4">White Label</h2>
                        <div className="mx-3 lg:mx-0">
                            <ThemeButton
                                text="Add White Label"
                                style="mb-5 h-[34px]"
                                icon={<PlusOutlined />}
                                type="primary"
                                action={() => {
                                    setIsModal(true);
                                    setModalType('add');
                                    setInitialValues({});
                                }}
                            />
                        </div>
                        {data?.map((item, key) => {
                            const isExpanded = expandedRowKey === key;
                            return (
                                <div key={key} className="bg-white border-b border-[#E4E4E7]">
                                    <div className="flex justify-between items-center p-3 cursor-pointer">
                                        <div className="flex gap-2 items-center">
                                            <div>
                                                <h1 className="text-sm font-medium pb-1 text-[#18181B]">
                                                    {item.whitelableName || 'N/A'}
                                                </h1>
                                                <p className="font-normal text-xs text-darkGray">
                                                    {item.whitelabelId || 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex gap-4 items-center">
                                            <div className="" onClick={() => handleExpandRow(key)}>
                                                {isExpanded ? (
                                                    <UpOutlined width={10} height={10} />
                                                ) : (
                                                    <DownOutlined width={10} height={10} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {isExpanded && (
                                        <div className="p-3">
                                            <ExpandedRow record={item} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        <Pagination
                            total={pagination.total}
                            className="py-3 flex justify-center"
                            current={pagination.current}
                            onChange={handlePagination}
                        />
                    </>
                ) : (
                    <div className="flex items-center justify-center h-[50vh] ">
                        <Spin />
                    </div>
                )}
            </div>

            <WhiteLabelModal
                modalType={modalType}
                open={isModal}
                handleClose={handleClose}
                initialValues={initialValues}
                getWhiteLabelUsers={getWhiteLabelUsers}
                pageNo={pagination.current}
            />
            <DeleteModal
                open={deleteModal}
                title="Delete white label"
                content="Are you sure you want to delete the white label"
                name={toDelete.whitelableName || 'N/A'}
                toDelete={toDelete._id}
                handleClose={modalClose}
                handleDelete={deleteWhiteLabel}></DeleteModal>
        </>
    );
};

export default WhiteLabel;
