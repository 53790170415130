import React, { useState } from 'react';
import { Modal, Button, Typography, Space } from 'antd';
import {
    ArrowLeftOutlined,
    PaperClipOutlined,
    FileOutlined,
    RightOutlined,
    LeftOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import moment from 'moment';
import ThemeButton from '../common/ThemeButton';

const WithdrawalAndTPPConfirmationModal = ({
    open,
    handleClose,
    onBack,
    attachments,
    selectedCurrency,
    formData,
    submitWithdrawal,
    initialTitle,
    confirmTitle,
    isLoading,
}) => {
    const imgBaseUrl = useSelector(state => state.config.image_base_url);
    const [showDetails, setShowDetails] = useState(false);
    const [isPreview, setIsPreview] = useState(true);
    const [showAttachments, setShowAttachments] = useState(false);

    const openPreview = () => {
        setShowDetails(false);
        setIsPreview(true);
        setShowAttachments(false);
    };

    const openDetails = () => {
        setIsPreview(false);
        setShowDetails(true);
        setShowAttachments(false);
    };

    const openAttachments = () => {
        setIsPreview(false);
        setShowDetails(false);
        setShowAttachments(true);
    };

    return (
        <Modal
            title={
                <Space>
                    <Button
                        type="text"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                            onBack(initialTitle);
                        }}
                        className="mr-2 -ml-2"
                    />
                    <p className="text-2xl font-semibold relative">{confirmTitle}</p>
                </Space>
            }
            centered
            open={open}
            onCancel={handleClose}
            destroyOnClose
            footer={[
                <div key="block" className="grid grid-cols-2 gap-[10px] lg:max-w-[50%] ml-auto">
                    <ThemeButton
                        shape="round"
                        key="back"
                        dataE2e="cancel-btn"
                        text="Cancel"
                        action={() => {
                            onBack(initialTitle);
                        }}
                    />
                    <ThemeButton
                        key="submit"
                        loading={isLoading}
                        type="primary"
                        shape="round"
                        dataE2e={isLoading ? 'Processing' : 'Confirm'}
                        text={isLoading ? 'Processing' : 'Confirm'}
                        action={submitWithdrawal}
                    />
                </div>,
            ]}>
            {isPreview && (
                <div className="flex flex-col border rounded-lg">
                    <Space direction="vertical">
                        <Space direction={'vertical'} className="border-b w-full p-[12px]">
                            <Space
                                direction={'horizontal'}
                                className="w-full justify-between items-center">
                                <Typography.Text
                                    strong
                                    className="text-gray-700 text-base font-medium">
                                    Transaction Information:
                                </Typography.Text>
                                {attachments.length > 0 && (
                                    <div
                                        className="flex rounded-full bg-[#F4F4F5] px-2 py-1 cursor-pointer gap-[5px]"
                                        onClick={openAttachments}>
                                        <PaperClipOutlined className="text-lg text-[#000] my-0 mx-[5px]" />
                                        <Typography.Text className="text-base font-medium">
                                            +{attachments.length}
                                        </Typography.Text>
                                    </div>
                                )}
                            </Space>
                            <Typography.Text className="text-sm text-gray-600">
                                Withdraw amount:
                            </Typography.Text>
                            <Space
                                direction={'horizontal'}
                                className="w-full justify-between items-center font-semibold text-gray-800">
                                <Space direction={'horizontal'}>
                                    <div className="withdrawal-confirmation-icon">
                                        <img
                                            src={`${imgBaseUrl}${selectedCurrency.currencyIcon}`}
                                            alt="currencyIcon"
                                        />
                                    </div>
                                    <Typography.Text className="text-xl">
                                        {selectedCurrency.currencyFullName}
                                    </Typography.Text>
                                </Space>
                                <Typography.Text className="text-xl">{`${getCurrencySymbol(selectedCurrency.currencyShortName)} ${toFixedTrunc(formData.amount)} (${selectedCurrency.currencyShortName})`}</Typography.Text>
                            </Space>
                        </Space>
                    </Space>
                    <div className="flex flex-col justify-center items-start gap-3 bg-gray-50 pb-3">
                        <Space direction={'vertical'} className="border-b w-full p-[12px]">
                            <Typography.Text className="text-base text-gray-900 font-medium">
                                {formData.beneficiaryName || '---'}
                            </Typography.Text>
                            <Typography.Text className="text-sm text-gray-600 font-normal">
                                {formData.beneficiaryAddress || '---'}
                            </Typography.Text>
                        </Space>
                        <Button
                            type="link"
                            onClick={openDetails}
                            className="text-[#1570EF] text-base font-medium">
                            Show more information
                            <RightOutlined className="text-xs font-extrabold" />
                        </Button>
                    </div>
                </div>
            )}
            {showDetails && (
                <div className="flex flex-col w-full border rounded-lg">
                    <Space
                        direction={'horizontal'}
                        className="flex justify-between gap-[10px] border-b w-full p-3">
                        <Button
                            onClick={openPreview}
                            type="link"
                            size="small"
                            icon={<LeftOutlined className="text-xs font-extrabold" />}
                            className="text-[#1570EF] text-base font-medium">
                            Go back
                        </Button>
                        <Typography.Text className="text-gray-600 text-base font-normal">
                            {moment().format('DD.MM.YY HH:mm')}
                        </Typography.Text>
                    </Space>
                    <Space direction="vertical" className="w-full bg-gray-50 p-3">
                        <Space direction={'vertical'} className="border-b w-full py-3">
                            <Typography.Text className="text-base font-medium text-gray-900 py-3">
                                {formData.beneficiaryName || '---'}
                            </Typography.Text>
                            <Typography.Text className="text-sm text-gray-600">
                                {formData.beneficiaryAddress || '---'}
                            </Typography.Text>
                        </Space>
                        <Space direction={'vertical'} className="border-b w-full py-3">
                            <Space
                                direction={'horizontal'}
                                className="w-full justify-between items-center">
                                <Typography className="text-sm text-gray-600">SWIFT:</Typography>
                                <Typography className="text-sm text-gray-900 font-medium">
                                    {formData.swift || '---'}
                                </Typography>
                            </Space>
                            <Space
                                direction={'horizontal'}
                                className="w-full justify-between items-center">
                                <Typography className="text-sm text-gray-600">IBAN:</Typography>
                                <Typography className="text-sm text-gray-900 font-medium">
                                    {formData.iban || '---'}
                                </Typography>
                            </Space>
                        </Space>
                        <Space direction={'vertical'} className="border-b w-full py-3">
                            <Typography className="text-base text-gray-900 font-medium">
                                {formData.bankName || '---'}
                            </Typography>
                            <Typography className="text-sm text-gray-600">
                                {formData.bankAddress || '---'}
                            </Typography>
                        </Space>
                        <Space className="border-b w-full py-3">
                            <Typography className="text-sm text-gray-600">
                                <span className="text-gray-900 font-medium">Reference: </span>
                                {formData.reference || '---'}
                            </Typography>
                        </Space>
                        <Space className="w-full py-3">
                            <Typography className="text-sm text-gray-600">
                                <span className="text-gray-900 font-medium">
                                    Additional information:{' '}
                                </span>
                                {formData.additionalInfo || '---'}
                            </Typography>
                        </Space>
                    </Space>
                </div>
            )}
            {showAttachments && (
                <Space direction="vertical w-full border rounded-lg">
                    <Space direction={'horizontal'} className="w-full p-3">
                        <Button
                            onClick={openPreview}
                            type="link"
                            size="small"
                            icon={<LeftOutlined className="text-xs font-extrabold" />}
                            className="text-[#1570EF] text-base font-medium">
                            Go back
                        </Button>
                    </Space>
                    <Space direction={'vertical'} className="border-b w-full px-3 pb-3" size={12}>
                        {attachments.map(attachment => (
                            <Space
                                key={attachment.uid}
                                className="w-full h-9"
                                direction={'vertical'}>
                                <Space size={12}>
                                    <Space className="flex items-center justify-center h-[36px] w-[36px] bg-gray-100 rounded-md">
                                        <FileOutlined />
                                    </Space>
                                    <Typography>{attachment.name}</Typography>
                                </Space>
                            </Space>
                        ))}
                    </Space>
                </Space>
            )}
        </Modal>
    );
};

export default WithdrawalAndTPPConfirmationModal;
