import { SET_NOTIFICATION_COUNT } from '../actions/actionTypes';

const initialState = {
    notificationCount: 0,
};

const setNotificationCount = (state, action) => {
    switch (action.type) {
        case SET_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.value,
            };
        default:
            return state;
    }
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION_COUNT:
            return setNotificationCount(state, action);
        default:
            return state;
    }
};

export default notificationReducer;
