import React from 'react';
import moment from 'moment';
import { toFixedTrunc } from '../utils/common';

const TransactionSummary = ({
    totalCount,
    totalUsd,
    fromDate,
    toDate,
    isMobile,
    showMore,
    setShowMore,
}) => {
    const handleShow = () => {
        if (showMore === 'Show More') {
            setShowMore('Show Less');
        } else {
            setShowMore('Show More');
        }
    };
    return (
        <>
            {!isMobile ? (
                <div className="flex w-full gap-1 mt-6">
                    <div className="h-14 w-[60%] p-4 bg-[#fcfcfc] rounded-lg border border-[#e3e3e7] flex-col justify-center items-start gap-3 inline-flex">
                        <div className="justify-start items-center gap-6 inline-flex">
                            <div className="justify-start items-center gap-2 flex">
                                <div className="text-[#51515b] text-sm font-normal leading-tight">
                                    Number of Transactions:
                                </div>
                                <div className="text-[#17171b] text-base font-medium leading-normal">
                                    {totalCount}
                                </div>
                            </div>
                            <div className="w-px h-6 relative bg-[#e3e3e7]" />
                            <div className="justify-start items-center gap-2 flex">
                                <div className="text-[#51515b] text-sm font-normal leading-tight">
                                    Net USD Equivalent:
                                </div>
                                <div className="text-[#17171b] text-base font-medium leading-normal">
                                    {toFixedTrunc(totalUsd, 2) || '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-14 w-[40%] p-4 bg-[#fcfcfc] rounded-lg border border-[#e3e3e7] justify-start items-center gap-3 inline-flex">
                        <div className="justify-start items-center gap-6 flex">
                            <div className="justify-start items-center gap-2 flex">
                                <div className="text-[#51515b] text-sm font-normal leading-tight">
                                    Date Range From:
                                </div>
                                <div className="text-[#17171b] text-base font-medium leading-normal">
                                    {fromDate
                                        ? moment(fromDate).isValid() &&
                                          moment(fromDate).format('DD.MM.YYYY')
                                        : '-'}
                                </div>
                            </div>
                            <div className="w-px h-6 relative bg-[#e3e3e7]" />
                            <div className="justify-start items-center gap-2 flex">
                                <div className="text-[#51515b] text-sm font-normal leading-tight">
                                    Date Range To:
                                </div>
                                <div className="text-[#17171b] text-base font-medium leading-normal">
                                    {toDate ? moment(toDate).format('DD.MM.YYYY') : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="p-3 mx-3 mb-3 bg-white border-[1px] rounded-lg mt-2">
                    <div className="flex justify-between items-center w-full">
                        <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                            Number of Transactions:
                        </p>
                        <p className="h-[18px] text-[#17171b] text-[13px] font-medium leading-[18px]">
                            {totalCount}
                        </p>
                    </div>
                    <hr className="my-3" />
                    <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                        Net USD Equivalent:
                    </p>
                    <p className="h-[18px] text-[#17171b] text-[13px] mt-2 font-medium leading-[18px]">
                        {toFixedTrunc(totalUsd, 2)}
                    </p>

                    {showMore === 'Show Less' && (
                        <>
                            <hr className="my-3" />
                            <div className="flex justify-between items-center w-full my-3">
                                <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                                    Date Range From:
                                </p>
                                <p className="h-[18px] text-[#17171b] text-[13px] font-medium leading-[18px]">
                                    {fromDate ? moment(fromDate).format('DD.MM.YYYY') : '-'}
                                </p>
                            </div>
                            <div className="flex justify-between items-center w-full my-3">
                                <p className="h-[18px] text-[#51515b] text-[13px] font-normal leading-[18px]">
                                    Date Range To:
                                </p>
                                <p className="h-[18px] text-[#17171b] text-[13px] font-medium leading-[18px]">
                                    {toDate ? moment(toDate).format('DD.MM.YYYY') : '-'}
                                </p>
                            </div>
                        </>
                    )}

                    <hr className="my-3" />
                    <div
                        className="text-[#156fee] text-sm font-medium cursor-pointer hover:underline leading-none"
                        onClick={handleShow}>
                        {showMore}
                    </div>
                </div>
            )}
        </>
    );
};

export default TransactionSummary;
