/* eslint-disable no-unsafe-optional-chaining */
import { Button, Divider, Input, Table, message } from 'antd';
import { SearchOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import CustomPagination from '../layout/CustomPagination';
import { Delete } from '../../utils/Images';
import { useSelector } from 'react-redux';
import Dragger from 'antd/es/upload/Dragger';
import apiRequest from '../../utils/api';
import MerchantAccountModal from './MerchantAccountModal';
import { getPresignedUrl } from '../../utils/common';
import DeleteModal from '../modals/DeleteModal';
import ThemeButton from '../common/ThemeButton';

const MerchantAccount = () => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientData = useSelector(state => state.account.clientData);
    const [merchantModal, setMerchantModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState('');

    const email = localStorage.getItem('email');
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });
    const [merchantData, setMerchantData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputValue, setInputValue] = useState(`${merchantData?.transferInFees || ''}%`);
    const inputRef = useRef(null);

    const getMerchant = async () => {
        setLoading(true);
        const payload = {
            clientId: clientData._id,
            page: pagination.current,
            email,
            searchQuery: searchText,
        };
        try {
            const res = await apiRequest('/fetch-merchant', 'POST', payload);
            if (res.success) {
                if (pagination.current === 1) {
                    setMerchantData(res.data.merchants[0]);
                    setInputValue(res.data.merchants[0].transferInFees + '%');
                } else {
                    setMerchantData({
                        ...merchantData,
                        linkedClientAccounts: [
                            ...merchantData.linkedClientAccounts,
                            ...res?.data.merchants[0].linkedClientAccounts,
                        ],
                    });
                    setInputValue(res.data.merchants[0].transferInFees + '%');
                }
                setPagination(prev => ({ ...prev, total: res?.data.totalClientsLinked }));
            } else {
                message.error(res.error);
            }
        } catch (err) {
            console.log(err.message, 'Fetch Error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            getMerchant();
        }, 500);
        return () => clearTimeout(getData);
    }, [searchText, pagination.current]);

    useEffect(() => {
        if (searchText) {
            setPagination(pre => ({ ...pre, current: 1 }));
        }
    }, [searchText]);

    const modalClose = () => {
        setDeleteModal(false);
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'ID',
            dataIndex: 'clientId',
            key: 'clientId',
        },
        {
            key: 'actions',
            render: record => (
                <>
                    <Button
                        className="border-0"
                        onClick={() => {
                            setDeleteModal(true);
                            setSelectedRecord(record);
                            setDeleteAccountModal(false);
                        }}
                        icon={<DeleteOutlined />}></Button>
                </>
            ),
        },
    ];

    const data = merchantData
        ? merchantData?.linkedClientAccounts.map(client => ({
              id: client._id,
              name: client.name,
              clientId: client.clientId,
              email: client.clientEmail,
          }))
        : [];

    const deleteClient = async ({ clientId, unlinkAllClients }) => {
        setLoading(true);
        const payload = {
            clientId,
            merchantId: merchantData._id,
            email,
            unlinkAllClients,
        };
        try {
            const res = await apiRequest('/unlink-merchant', 'POST', payload);
            if (res.success) {
                if (pagination.current === 1) getMerchant();
                else setPagination(pre => ({ ...pre, current: 1 }));
            } else {
                message.error(res.error);
            }
            modalClose();
        } catch (err) {
            console.log(err.message, 'Fetch Error');
        } finally {
            setLoading(false);
        }
    };

    const deleteAccount = async merchantId => {
        setLoading(true);
        const payload = {
            merchantId: merchantData._id,
            email,
        };
        try {
            const res = await apiRequest('/delete-merchant', 'DELETE', payload);
            getMerchant();
            modalClose();
        } catch (err) {
            console.log(err.message, 'Fetch Error');
        } finally {
            setLoading(false);
        }
    };

    const updateMerchant = async () => {
        setLoading(true);
        let imgUrl;
        if (selectedFile) {
            const payload = {
                imageType: 'junoClientMerchantLogo',
                merchantName: data.merchantName,
                email: email,
            };
            const imageUrl = `${imageBaseUrl}/juno-client-merchant-logos/${data.merchantName}/logo`;
            imgUrl = await getPresignedUrl(payload, selectedFile, imageUrl);
        }

        const payload = {
            merchantName: merchantData.merchantName,
            transferInFees: merchantData.transferInFees,
            merchantLogo: imgUrl,
            merchantId: merchantData._id,
            email: email,
        };
        try {
            const res = await apiRequest('/update-merchant', 'POST', payload);
            if (res.success) {
                getMerchant();
                setIsUpdate(false);
            } else {
                message.error(res.error);
            }
        } catch (err) {
            console.log(err.message, 'Fetch Error');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = e => {
        const { value } = e.target;
        const sanitizedValue = value.replace(/[^0-9.]/g, '');
        setMerchantData(prevState => ({
            ...prevState,
            transferInFees: sanitizedValue,
        }));
        setInputValue(sanitizedValue);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(() => {
            setIsUpdate(true);
        }, 2000);

        setDebounceTimeout(newTimeout);
    };

    const handleFocus = () => {
        const currentValue = merchantData.transferInFees;
        setInputValue(currentValue);
    };

    const handleBlur = () => {
        const currentValue = merchantData.transferInFees;
        setInputValue(currentValue + '%');
    };
    const handleFileChange = info => {
        setSelectedFile(info.file);
        setIsUpdate(true);
        message.success(`${info.file.name} file selected successfully.`);
    };

    useEffect(() => {
        if (isUpdate) {
            updateMerchant();
            setIsUpdate(false);
        }
    }, [isUpdate]);

    const handleTableChange = newPagination => {
        const { current } = newPagination;

        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));
    };
    return (
        <>
            <div>
                {!merchantData ? (
                    <div className="flex items-center justify-center text-center h-[50svh]">
                        <div className="">
                            <h1 className="text-lg font-semibold">Create Merchant Account</h1>
                            <p className="text-[#51525C] text-base font-medium ">
                                Setup merchant account for{' '}
                                <span className="text-[#18181B]">{clientData.name}</span> and link
                                users to it
                            </p>
                            <ThemeButton
                                type="primary"
                                autoWidth={true}
                                action={() => setMerchantModal(true)}
                                text="Create Merchant Account"
                                style="mr-2 my-5"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="overflow-auto h-[600px] hide-scrollbar pb-6">
                        <div className="mt-6 lg:w-1/2">
                            <h2 className="text-[#51525C] text-sm font-normal mb-3">
                                Merchant name
                            </h2>
                            <Input
                                name="merchantName"
                                onChange={handleInputChange}
                                value={merchantData?.merchantName || ''}
                                placeholder="Search Account ID, Account name"
                                className=" p-2"
                            />
                        </div>
                        <div className="flex items-center justify-between mt-3">
                            <div className="lg:w-1/2 w-full">
                                <h2 className="text-[#51525C] text-sm font-normal mb-3 mt-6">
                                    Upload a logo
                                </h2>
                                <Dragger
                                    showUploadList={false}
                                    maxCount={1}
                                    name="file"
                                    multiple={false}
                                    beforeUpload={() => false}
                                    onChange={handleFileChange}>
                                    {selectedFile ? (
                                        <div className="text-center">
                                            <img
                                                src={URL.createObjectURL(selectedFile)}
                                                alt="Preview"
                                                className="img-fluid mx-auto mb-3 max-h-[200px]"
                                            />
                                            <p>{selectedFile.name}</p>
                                        </div>
                                    ) : merchantData?.merchantLogo ? (
                                        <div className="text-center">
                                            <img
                                                src={merchantData.merchantLogo}
                                                alt="Preview"
                                                className="img-fluid mx-auto mb-3 max-h-[200px]"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <p className="ant-upload-drag-icon">
                                                {/* <InboxOutlined /> */}
                                            </p>
                                            <p className="ant-upload-text">
                                                <span className="text-primary font-semibold">
                                                    Click to upload
                                                </span>{' '}
                                                or drag and drop
                                            </p>
                                            <p className="ant-upload-hint">
                                                PNG or JPG (max. 800x400px)
                                            </p>
                                        </>
                                    )}
                                </Dragger>
                            </div>
                        </div>
                        <div className="mt-6 lg:w-1/2">
                            <h2 className="text-[#51525C] text-sm font-normal my-3">
                                Merchant Transfer Fee
                            </h2>
                            <Input
                                ref={inputRef}
                                name="transferInFees"
                                onChange={handleInputChange}
                                value={inputValue}
                                placeholder="Search Account ID, Account name"
                                className=" p-2"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className="my-6">
                            <h1 className="lg:text-lg text-base font-semibold text-[#18181B]">
                                Link Account
                            </h1>
                            <p className="text-[#51525C] text-base font-normal">
                                Manage accounts that are linked to{' '}
                                <span className="text-[#18181B] font-bold">
                                    {merchantData?.merchantName}
                                </span>
                            </p>
                        </div>
                        <div className="flex overflow-auto justify-between items-center gap-2">
                            <Input
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                placeholder="Search Account ID, Account name"
                                suffix={<SearchOutlined />}
                                className="md:w-[400px] w-4/5 p-2"
                            />
                            <div className="hidden md:flex">
                                <ThemeButton
                                    text="Link New"
                                    style="mr-2"
                                    icon={<PlusOutlined />}
                                    type="primary"
                                    action={() => setMerchantModal(true)}
                                />
                                {merchantData?.linkedClientAccounts.length > 0 && (
                                    <Button
                                        className="py-2 px-3 h-auto"
                                        onClick={() => {
                                            setSelectedRecord('');
                                            setDeleteModal(true);
                                            setDeleteAccountModal(false);
                                        }}>
                                        Remove All
                                    </Button>
                                )}
                            </div>
                            <div className="flex md:hidden md:w-1/5">
                                <Button
                                    onClick={() => setMerchantModal(true)}
                                    type="primary"
                                    className="py-2 px-3 h-auto bg-[#18181B] mr-2"
                                    icon={<PlusOutlined />}
                                />
                                {merchantData?.linkedClientAccounts.length > 0 && (
                                    <Button
                                        onClick={() => {
                                            setSelectedRecord('');
                                            setDeleteModal(true);
                                            setDeleteAccountModal(false);
                                        }}
                                        className="py-2 px-3 h-auto"
                                        icon={
                                            <img
                                                src={`${imageBaseUrl}${Delete}`}
                                                alt="Delete"
                                                className="inline"
                                                width={24}
                                                height={24}
                                            />
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className="mt-6 accounts-page md:block hidden">
                            <Table
                                loading={loading}
                                dataSource={data}
                                columns={columns}
                                pagination={
                                    pagination.total > 9
                                        ? {
                                              position: ['bottomCenter'],
                                              itemRender: CustomPagination,
                                              showSizeChanger: false,
                                              current: pagination.current,
                                              pageSize: 10,
                                              total: pagination.total,
                                          }
                                        : false
                                }
                                onChange={handleTableChange}
                            />
                        </div>
                        <div className="md:hidden">
                            {data.map(item => (
                                <div
                                    className="flex justify-between items-center px-3 py-5 border-b"
                                    key={item.id}>
                                    <div className="">
                                        <h1 className="text-sm font-medium text-[#18181B]">
                                            {item.name}
                                        </h1>
                                        <p className="text-sm font-medium text-[#70707B]">
                                            ID:{item.id}
                                        </p>
                                    </div>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setDeleteModal(true);
                                            setSelectedRecord(item);
                                            setDeleteAccountModal(false);
                                        }}>
                                        <img
                                            src={`${imageBaseUrl}${Delete}`}
                                            alt="Delete"
                                            className="inline"
                                            width={20}
                                            height={20}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Divider type="horizontal" className="my-6 lg:block hidden" />
                        <div className="lg:w-2/4 mt-6">
                            <h1 className="text-lg font-semibold text-[#18181B]">
                                Delete Merchant Account
                            </h1>
                            <p className="text-[#51525C] text-base font-normal pt-3 pb-6">
                                Delete{' '}
                                <span className="text-[#18181B] font-bold">
                                    {merchantData?.merchantName}
                                </span>{' '}
                                merchant account. This action cannot be undone.
                            </p>
                            <Button
                                // type="primary"
                                className="py-2 border-[#FECDCA] px-3 h-auto bg-[#FECDCA] mr-2 text-[#B42318]"
                                onClick={() => {
                                    setDeleteModal(true);
                                    setDeleteAccountModal(true);
                                    setSelectedRecord('');
                                }}>
                                Delete Account
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            {merchantModal && (
                <MerchantAccountModal
                    merchantData={merchantData}
                    merchantModal={merchantModal}
                    setMerchantModal={setMerchantModal}
                    onSave={() => {
                        setMerchantModal(false);
                        getMerchant();
                    }}
                    onReset={() => {
                        setMerchantModal(false);
                        setDeleteModal(true);
                    }}
                />
            )}
            <DeleteModal
                open={deleteModal && deleteAccountModal}
                title={'Delete merchant account'}
                content={
                    <>
                        Are you sure you would like to delete{' '}
                        <span className="font-bold">{merchantData?.merchantName}</span> merchant
                        account? This action cannot be undone.
                    </>
                }
                handleClose={modalClose}
                handleDelete={deleteAccount}
            />
            <DeleteModal
                open={deleteModal && !deleteAccountModal}
                title={
                    selectedRecord && selectedRecord.clientId
                        ? 'Delete account'
                        : 'Remove linked accounts'
                }
                content={
                    selectedRecord && selectedRecord.clientId ? (
                        <>
                            Are you sure you would like to delete{' '}
                            <span className="font-semibold">{selectedRecord.name}</span> from the
                            account list?
                        </>
                    ) : (
                        `Are you sure you want to remove ${data.length} linked accounts? This action cannot be undone.`
                    )
                }
                toDelete={selectedRecord.id}
                handleClose={modalClose}
                handleDelete={clientId =>
                    deleteClient({
                        clientId: clientId,
                        unlinkAllClients: !(selectedRecord && selectedRecord.id),
                    })
                }
            />
        </>
    );
};

export default MerchantAccount;
