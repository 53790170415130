import React from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { SuccessTick, Error, WarningIcon } from '../../utils/Images';
import { getCurrencySymbol } from '../../utils/common';

const ConfirmBalanceChangeModal = ({ open, title, balance, handleClose, handleChange }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    return (
        <div>
            <Modal
                open={open}
                width={'422px'}
                height={'330px'}
                destroyOnClose
                centered
                footer={false}
                className="transaction-result"
                closable={false}>
                <div className="flex flex-col gap-4 items-center pt-12 border-t-4 rounded-t-md border-t-[#F79009] px-[24px] mb-[24px]">
                    <img className="" src={`${imageBaseUrl}${WarningIcon}`} alt="icon" />

                    <p className="font-semibold text-xl">{title}</p>
                    <p className="text-xl text-primary text-center">
                        Are you sure you want to change the displayed balances for{' '}
                        <span className="font-medium">{`\`${balance.currencyFullName} (${balance.currencyShortName})\``}</span>
                        ?
                    </p>
                </div>
                <hr />
                <div className="flex justify-center rounded-b-3xl bg-slate-100 p-6">
                    <Button
                        onClick={handleClose}
                        className={`px-5 py-3 w-[374px] h-[46px] rounded-3xl font-bold bg-[#E4E4E7] text-sm`}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleChange}
                        className={`px-5 py-3 w-[374px] h-[46px] rounded-3xl text-white font-semibold bg-black text-sm ml-[10px]`}>
                        Change
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmBalanceChangeModal;
