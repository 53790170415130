import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const BreadCrumHeader = ({ subTabs, currentTab }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const items = [
        {
            href: '/',
            title: (
                <div>
                    <img
                        alt="dashboardIcon.svg"
                        src={`${imageBaseUrl}/adminUi/dashboardIcon.svg`}
                        className="w-[22px] h-[22px]"
                    />
                </div>
            ),
        },
        ...(subTabs
            ? subTabs.map(subTab => ({
                  href: subTab?.href,
                  title: (
                      <div className="flex justify-center items-center gap-[8px]">
                          {subTab?.icon && (
                              <img
                                  alt={`${subTab?.icon}`}
                                  src={`${imageBaseUrl}/adminUi/${subTab?.icon}`}
                                  className="w-[20px] h-[20px]"
                              />
                          )}
                          <span className="text-sm font-medium">{subTab?.title}</span>
                      </div>
                  ),
              }))
            : []),
        currentTab && {
            title: (
                <div className="flex justify-center items-center gap-[8px]">
                    {currentTab?.icon && (
                        <img
                            alt={`${currentTab?.icon}`}
                            src={`${imageBaseUrl}/adminUi/${currentTab?.icon}`}
                            className="w-[24px] h-[24px]"
                        />
                    )}
                    <span>{currentTab?.title}</span>
                </div>
            ),
        },
    ].filter(Boolean); // Filter out null or undefined items

    return (
        <div className="mb-[16px] h-auto">
            <Breadcrumb
                separator={<RightOutlined />}
                className="p-[6px] flex gap-[8px] items-center"
                items={items}
            />
        </div>
    );
};

export default BreadCrumHeader;
