import React from 'react';
import { Form, Input } from 'antd';
import { getInitials } from '../../utils/common';
import ImagePlaceholder from '../common/ImagePlaceholder';

const FormInput = ({
    label,
    name,
    type,
    initialValue,
    value,
    defaultValue,
    placeholder,
    required,
    disabled,
    rules,
    help,
    readOnly,
    className,
    formClassName,
    dataE2e,
    isMerchantTransfer,
    selectedMerchant,
}) => {
    const initials = selectedMerchant && getInitials(selectedMerchant?.merchantName);
    return (
        <Form.Item
            label={
                <p className="text-sm lg:text-base text-wrap leading-5 text-start overflow-visible">
                    {label}
                </p>
            }
            initialValue={initialValue}
            name={name}
            required={required}
            rules={rules}
            help={help}
            className={`mt-[30px] ${formClassName}`}>
            <div className="relative w-full sm:w-[320px] mr-auto">
                {isMerchantTransfer ? (
                    <div
                        className={`w-full lg:w-[320px] h-[50px] text-base rounded-3xl p-2 flex items-center bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[12px] ${className}`}
                        data-e2e={dataE2e}>
                        {selectedMerchant?.merchantLogo ? (
                            <img
                                src={
                                    selectedMerchant?.merchantLogo ||
                                    `https://via.placeholder.com/40x40.png?text=${initials}`
                                }
                                alt={`${selectedMerchant?.merchantName} Logo`}
                                className="w-8 h-8 rounded-full"
                            />
                        ) : (
                            <ImagePlaceholder initials={initials} />
                        )}
                        <p className="ml-2 text-[#51525C] truncate max-w-xs">
                            {selectedMerchant?.merchantName || placeholder}
                        </p>
                    </div>
                ) : (
                    <Input
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        disabled={disabled}
                        data-e2e={dataE2e}
                        className={`w-full lg:w-[320px] h-[50px] text-base rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px]  ${className}`}
                    />
                )}
            </div>
        </Form.Item>
    );
};

export default FormInput;
