import { getStatusComponent } from '../../utils/common';
import { CopyButton } from '../../utils/common';
import { Spin } from 'antd';
import { toFixedTrunc } from '../../utils/common';
import { getCurrencySymbol } from '../../utils/common';
import { fiatCurrencies } from '../../utils/constant';
import { useSelector } from 'react-redux';

export default function NotificationsDetailsList({ item, detailsLoader }) {
    const imgBaseUrl = useSelector(state => state.config.image_base_url);

    return (
        <>
            {!detailsLoader ? (
                <>
                    <div className="notif__details-list">
                        <div className="notif__details-item">
                            <p>Transaction Type:</p>
                            <p>{item?.type || '-'}</p>
                        </div>
                        <div className="notif__details-item">
                            <p>Transaction Status:</p>{' '}
                            <div className="flex">{getStatusComponent(item?.status?.code)}</div>
                        </div>
                        <div className="notif__details-item">
                            <p>Transaction Id:</p>{' '}
                            <div className="flex text-blue-600">
                                <a href={`/transactions/${item?.transactionId}`} target="_blank">
                                    {item?.transactionId}
                                </a>
                                <CopyButton msg={item?.transactionId} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Beneficiary Name:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.beneficiaryName || '-'}{' '}
                                <CopyButton msg={item?.transactionDetails[0]?.beneficiaryName} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Beneficiary Address:</p>{' '}
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.beneficiaryAddress || '-'}
                                <CopyButton msg={item?.transactionDetails[0]?.beneficiaryAddress} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Bank name:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.bankName || '-'}
                                <CopyButton msg={item?.transactionDetails[0]?.bankName} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Bank Address:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.bankAddress || '-'}
                                <CopyButton msg={item?.transactionDetails[0]?.bankAddress} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Account Number:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.beneficiaryAccountNumber || '-'}
                                <CopyButton
                                    msg={item?.transactionDetails[0]?.beneficiaryAccountNumber}
                                />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Sort Code:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.sortCode || '-'}
                                <CopyButton msg={item?.transactionDetails[0]?.sortCode} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>IBAN:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.iban || '-'}
                                <CopyButton msg={item?.transactionDetails[0]?.iban} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>SWIFT:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.swift || '-'}
                                <CopyButton msg={item?.transactionDetails[0]?.swift} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Currency:</p>
                            <div className="flex items-center">
                                <img
                                    src={`${imgBaseUrl}${fiatCurrencies.find(currency => currency.symbol === item?.currency)?.icon}`}
                                    alt="currency"
                                    className="w-5 h-5 mr-2"
                                />
                                {item?.currency}
                                <CopyButton msg={item?.currency || '-'} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Amount:</p>
                            <div className="flex items-center">
                                {getCurrencySymbol(item?.currency)}{' '}
                                {toFixedTrunc(item?.transactionDetails[0]?.amount) || '-'}
                                <CopyButton msg={item?.transactionDetails[0]?.amount} />
                            </div>
                        </div>
                        <div className="notif__details-item">
                            <p>Transaction Fee:</p>
                            <div className="flex items-center">{item?.transactionFee}</div>
                        </div>
                        <div className="notif__details-item one-row">
                            <p>Reference:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.reference || '-'}
                            </div>
                        </div>
                        <div className="notif__details-item one-row">
                            <p>Additional Info:</p>
                            <div className="flex items-center">
                                {item?.transactionDetails[0]?.additionalBankDetails || '-'}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex items-center justify-center h-[50vh] ">
                    <Spin />
                </div>
            )}
        </>
    );
}
