import React, { useEffect, useState } from 'react';
import { Switch, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import { singleClientData } from '../../redux/actions/accountActions';
import ThemeButton from '../common/ThemeButton';
import { RedoOutlined } from '@ant-design/icons';

const SettingsTabs = () => {
    const clientData = useSelector(state => state.account.clientData);
    const [cryptoTab, setCryptoTab] = useState(
        clientData ? clientData.cryptoTab === 'Enable' : false,
    );
    const [fiatTab, setFiatTab] = useState(clientData ? clientData.fiatTab === 'Enable' : true);
    const [cryptoLoading, setCryptoLoading] = useState(false);
    const [fiatLoading, setFiatLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const email = localStorage.getItem('email');
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        setCryptoTab(clientData.cryptoTab === 'Enable');
        setFiatTab(clientData.fiatTab === 'Enable');
    }, [clientData]);

    const getClientsDetails = async () => {
        const body = {
            email,
            clientId: id,
            paymentProviderId: localStorage.getItem('paymentProviderId'),
        };

        try {
            const response = await apiRequest('/get-single-client-data', 'POST', body);

            if (response.success) {
                if (response.data) {
                    dispatch(singleClientData(response.data));
                }
            } else {
                message.error(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateClientSetting = async (updatedField, setLoading, revertSwitch) => {
        const body = {
            email: email,
            clientId: id,
            ...updatedField,
        };
        setLoading(true);
        try {
            const response = await apiRequest('/update-client-settings', 'POST', body);

            if (response.success) {
                message.success('Settings Updated');
                getClientsDetails();
            } else {
                revertSwitch();
                message.error(response.error);
            }
            setLoading(false);
        } catch (error) {
            revertSwitch();
            setLoading(false);
            console.error(error);
        }
    };

    const handleCryptoSwitchChange = checked => {
        if (!checked && !fiatTab) {
            message.error('You cannot disable both Crypto and Fiat.');
            return;
        }
        setCryptoTab(checked);
        const revertSwitch = () => setCryptoTab(!checked);
        updateClientSetting(
            { cryptoTab: checked ? 'Enable' : 'Disable' },
            setCryptoLoading,
            revertSwitch,
        );
    };

    const handleFiatSwitchChange = checked => {
        if (!checked && !cryptoTab) {
            message.error('You cannot disable both Crypto and Fiat.');
            return;
        }
        setFiatTab(checked);
        const revertSwitch = () => setFiatTab(!checked);
        updateClientSetting(
            { fiatTab: checked ? 'Enable' : 'Disable' },
            setFiatLoading,
            revertSwitch,
        );
    };

    const reset2FA = async checked => {
        if (typeof checked !== 'boolean') {
            setLoading(true);
        }

        const body = {
            email: email,
            clientEmail: clientData.clientEmail,
        };
        if (typeof checked === 'boolean') {
            body.enabled = checked;
        }

        const response = await apiRequest('/client-reset-2fa-token', 'POST', body);

        if (response.success) {
            message.success(response.data.msg);
            getClientsDetails();
        } else {
            message.error(response.error);
        }
        setLoading(false);
    };

    return (
        <div className="bg-white lg:pt-8 py-4 px-6 lg:px-0">
            <h1 className="font-semibold lg:text-lg text-base pb-5 lg:hidden">Settings</h1>
            <div className="flex justify-between items-start w-full pb-2">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Enable Crypto:</span>
                </div>
                <Switch
                    onChange={handleCryptoSwitchChange}
                    checked={cryptoTab}
                    loading={cryptoLoading}
                />
            </div>
            <div className="flex justify-between items-start py-2 w-full border-t border-grey">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">Enable Fiat:</span>
                </div>
                <Switch onChange={handleFiatSwitchChange} checked={fiatTab} loading={fiatLoading} />
            </div>
            <div className="flex justify-between items-start py-2 w-full border-t border-grey">
                <div className="">
                    <span className="text-sm font-normal text-darkGray">2FA Settings:</span>
                </div>
                <Switch
                    onChange={reset2FA}
                    checked={clientData?.twoFA?.enabled}
                    // loading={fiatLoading}
                />
            </div>
            <div className="w-[100px]">
                <ThemeButton
                    icon={<RedoOutlined />}
                    action={reset2FA}
                    text="Reset"
                    autoWidth={true}
                    style="reset-filter-btn !h-9 w-[100px] p-3"
                    loading={loading}
                />
            </div>
        </div>
    );
};

export default SettingsTabs;
