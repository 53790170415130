import CommonModal from './CommonModal';
import { Form, Input } from 'antd';
import React from 'react';

const ChangeBalanceForm = ({ open, title, balance, handleClose, handleSave, footerText, form }) => {
    return (
        <CommonModal
            title={title}
            open={open}
            footerText={footerText}
            // onFinish={handleSave}
            handleClose={handleClose}>
            <Form
                form={form}
                labelCol={{ style: { fontSize: '16px' } }}
                colon={false}
                layout="horizontal"
                className="modal-form h-[100%] lg:mt-[40px] mt-[36px]">
                <Form.Item
                    label={<p className=" text-sm lg:text-base">Current balance:</p>}
                    name="currentBalance"
                    className="mt-[30px]">
                    <div className="w-full sm:w-[320px] ml-auto">
                        <Input
                            defaultValue={balance.balanceAmount}
                            className="w-full h-[50px] text-base rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px] "
                        />
                    </div>
                </Form.Item>
                <Form.Item
                    label={<p className=" text-sm lg:text-base">New balance:</p>}
                    name="newBalance"
                    className="mt-[30px]">
                    <div className="w-full sm:w-[320px] ml-auto">
                        <Input
                            placeholder="Enter new balance"
                            className="w-full h-[50px] text-base rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px] "
                        />
                    </div>
                </Form.Item>
                <p className=" text-sm lg:text-base">Note:</p>
                <Form.Item name="currentBalance" className="mt-[10px]">
                    <Input.TextArea
                        rows={6}
                        placeholder="Add your note..."
                        className="w-full h-[50px] text-base rounded-[8px] p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px] "
                    />
                </Form.Item>
            </Form>
        </CommonModal>
    );
};

export default ChangeBalanceForm;
