import { combineReducers } from 'redux';
import configReducer from './configReducer';
import filtersReducer from './filtersReducer';
import dateFilterReducer from './dateFilterReducer';
import accountReducer from './accountReducer';
import notificationReducer from './notificationReducer';

const rootReducer = combineReducers({
    config: configReducer,
    filters: filtersReducer,
    dateFilter: dateFilterReducer,
    account: accountReducer,
    notification: notificationReducer,
});

export default rootReducer;
