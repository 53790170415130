import React, { useEffect, useState } from 'react';
import { Button, Input, message, Table } from 'antd';
import apiRequest from '../../utils/api';
import { useSelector } from 'react-redux';
import { Edit } from '../../utils/Images';
import useDebounce from '../../utils/useDebounce';
import CommonModal from '../modals/CommonModal';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { cryptoTransactionTypes, feeProfileOptions } from '../../utils/constant';

const CryptoFeeTab = () => {
    const email = localStorage.getItem('email');
    const [selectedTab, setSelectedTab] = useState('Buy');
    const [profileTab, setProfileTab] = useState('Standard');
    const [data, setData] = useState([]);
    const [editFeeModal, setEditFeeModal] = useState(false);
    const [fees, setFees] = useState({});
    const { clientData, cryptoBalancesData } = useSelector(state => state.account);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [exchangeColumns, setExchangeColumns] = useState([]);
    const [exchangeTableData, setExchangeTableData] = useState([]);
    const [editAllFee, setEditAllFee] = useState('');
    const [loading, setLoading] = useState(false);

    const cryptoTransactionLabels = cryptoTransactionTypes.map(item => item.label);
    const feeProfileLabels = feeProfileOptions.map(item => item.label);

    const getCryptoData = crypto => {
        const cryptoData = cryptoBalancesData.find(item => item.currencyShortName === crypto);
        return (
            <div className="flex gap-4 items-center lg:w-[200px] w-[150px]">
                <img
                    src={`${imageBaseUrl}${cryptoData?.currencyIcon}`}
                    width={30}
                    height={30}
                    alt="currencyIcon"
                />
                <p className=" text-[#18181B] text-sm font-normal">
                    {cryptoData?.blockchain} ({cryptoData?.currencyShortName})
                </p>
            </div>
        );
    };

    const columns = [
        {
            title: <p className="text-[#51525C] text-sm font-medium">Currency</p>,
            key: 'crypto',
            render: row => getCryptoData(row.fromCurrency || row.crypto),
        },
        {
            title: (
                <p className="lg:float-right lg:pr-[110px] text-[#51525C] text-sm font-medium">
                    Fee&apos;s
                </p>
            ),
            key: 'fee',
            render: row => (
                <Input
                    value={
                        fees[row.fromCurrency]?.focused
                            ? fees[row.fromCurrency]?.value || ''
                            : `${fees[row.fromCurrency]?.value || row.fee}%`
                    }
                    disabled={profileTab !== 'Bespoke'}
                    onChange={e =>
                        profileTab === 'Bespoke' &&
                        handleInputChange(row.fromCurrency, e.target.value)
                    }
                    onFocus={() => profileTab === 'Bespoke' && handleFocus(row.fromCurrency)}
                    onBlur={() => profileTab === 'Bespoke' && handleBlur(row.fromCurrency)}
                    className="p-[10px] lg:max-w-[148px] max-w-[136px] lg:float-end"
                />
            ),
        },
    ];

    useEffect(() => {
        if ((profileTab === 'Bespoke', selectedTab === 'Exchange')) {
            const uniqueToCurrencies = [...new Set(data.map(item => item.toCurrency))];

            const dynamicColumns = [
                {
                    title: 'Currency',
                    dataIndex: 'fromCurrency',
                    key: 'fromCurrency',
                    fixed: 'left',
                    render: row => getCryptoData(row),
                },
                ...uniqueToCurrencies.map(currency => ({
                    title: (
                        <div className="text-center lg:text-start ">
                            <LeftOutlined className="lg:hidden" />
                            <span className="px-5 lg:p-0">{currency}</span>
                            <RightOutlined className="lg:hidden" />
                        </div>
                    ),
                    dataIndex: currency,
                    key: currency,
                    render: (value, row) => (
                        <Input
                            value={
                                fees[row.fromCurrency]?.[currency]?.focused
                                    ? fees[row.fromCurrency]?.[currency]?.value || ''
                                    : `${fees[row.fromCurrency]?.[currency]?.value || value}%`
                            }
                            disabled={row.fromCurrency === currency}
                            onChange={e =>
                                handleExchangeInputChange(
                                    row.fromCurrency,
                                    currency,
                                    e.target.value,
                                )
                            }
                            onFocus={() => handleExchangeFocus(row.fromCurrency, currency)}
                            onBlur={() => handleExchangeBlur(row.fromCurrency, currency)}
                            className="p-[10px] w-[148px]"
                        />
                    ),
                })),
            ];
            setExchangeColumns(dynamicColumns);

            const fromCurrencies = [...new Set(data.map(item => item.fromCurrency))];

            const tableRows = fromCurrencies.map(fromCurrency => {
                const rowData = { key: fromCurrency, fromCurrency };

                uniqueToCurrencies.forEach(toCurrency => {
                    const conversion = data.find(
                        item =>
                            item.fromCurrency === fromCurrency && item.toCurrency === toCurrency,
                    );
                    rowData[toCurrency] = conversion ? conversion.fee : '-';
                });

                return rowData;
            });
            setExchangeTableData(tableRows);
        }
    }, [data, fees]);

    const handleExchangeInputChange = (fromCurrency, toCurrency, value) => {
        if (!handleInputValidation(value)) {
            return;
        }
        setFees(prev => ({
            ...prev,
            [fromCurrency]: {
                ...prev[fromCurrency],
                [toCurrency]: {
                    ...prev[fromCurrency]?.[toCurrency],
                    value: value.replace('%', ''),
                    focused: true,
                },
            },
        }));

        handleUpdateFee({ fromCurrency, fee: value.replace('%', ''), toCurrency });
    };

    const handleExchangeFocus = (fromCurrency, toCurrency) => {
        setFees(prev => ({
            ...prev,
            [fromCurrency]: {
                ...prev[fromCurrency],
                [toCurrency]: {
                    ...prev[fromCurrency]?.[toCurrency],
                    focused: true,
                    value: prev[fromCurrency]?.[toCurrency]?.value || '',
                },
            },
        }));
    };

    const handleInputValidation = value => {
        const numericValue = value.replace('%', '').trim();
        const isValidNumber = /^-?\d*(\.\d{0,2})?$/.test(numericValue);
        return isValidNumber && parseFloat(numericValue) >= 0;
    };

    const handleExchangeBlur = (fromCurrency, toCurrency) => {
        setFees(prev => ({
            ...prev,
            [fromCurrency]: {
                ...prev[fromCurrency],
                [toCurrency]: {
                    ...prev[fromCurrency]?.[toCurrency],
                    focused: false,
                    value: prev[fromCurrency]?.[toCurrency]?.value
                        ? `${prev[fromCurrency]?.[toCurrency]?.value}`
                        : prev[fromCurrency]?.[toCurrency]?.fee,
                },
            },
        }));
    };

    const handleInputChange = (fromCurrency, value) => {
        if (!handleInputValidation(value)) {
            return;
        }

        setFees(prev => ({
            ...prev,
            [fromCurrency]: {
                ...prev[fromCurrency],
                value: value.replace('%', ''),
                focused: true,
            },
        }));

        handleUpdateFee({ fromCurrency, fee: value.replace('%', '') });
    };

    const handleUpdateFee = useDebounce(async ({ fromCurrency, fee, toCurrency, editAll }) => {
        const body = {
            email,
            clientEmail: clientData?.clientEmail,
            clientId: clientData?.clientId,
            transactionType: selectedTab,
            currencyType: 'crypto',
            percentFee: fee || editAllFee,
            ...(fromCurrency && { fromCurrency }),
            ...(toCurrency && { toCurrency }),
            ...(editAll && { editAll }),
        };

        try {
            const response = await apiRequest('/update-crypto-fee-setting', 'POST', body);
            if (!response.success) {
                message.error(response.error || 'Failed to update fee settings');

                setFees(prev => ({
                    ...prev,
                    [fromCurrency]: {
                        ...prev[fromCurrency],
                        value: prev[fromCurrency]?.previousValue || prev[fromCurrency].value,
                    },
                }));
            } else {
                message.success('Fee updated successfully');
                getCryptoFeeSetting();
            }
        } catch (error) {
            console.error('Error while updating fee:', error);
            message.error('An unexpected error occurred while updating the fee settings.');
            setFees(prev => ({
                ...prev,
                [fromCurrency]: {
                    ...prev[fromCurrency],
                    value: prev[fromCurrency]?.previousValue || prev[fromCurrency].value,
                },
            }));
        }
    }, 1000);

    const handleFocus = currency => {
        setFees(prev => ({
            ...prev,
            [currency]: {
                ...prev[currency],
                focused: true,
                value: prev[currency]?.value || prev[currency]?.fee || '',
            },
        }));
    };

    const handleBlur = currency => {
        setFees(prev => ({
            ...prev,
            [currency]: {
                ...prev[currency],
                focused: false,
                value: prev[currency]?.value ? `${prev[currency]?.value}` : prev[currency]?.fee,
            },
        }));
    };

    const getCryptoFeeSetting = async () => {
        setLoading(true);
        const body = {
            email,
            clientEmail: clientData?.clientEmail,
            transactionType: selectedTab,
            clientId: clientData?.clientId,
            feeProfile: profileTab,
        };

        try {
            const response = await apiRequest('/get-crypto-fee-setting', 'POST', body);
            const { success, data, error } = response || {};
            if (!success) {
                message.error(error || 'Failed to fetch fee settings');
                return { error: error || 'Request failed' };
            }
            const filteredData = data?.filter(item => item.profile === profileTab) ?? [];
            setData(filteredData);
            setLoading(false);
            handleCloseModal();
            return { success: true };
        } catch (error) {
            console.error('Error while fetching fee:', error);
            message.error('An error occurred while fetching the fee settings.');
            setLoading(false);
            return { error: 'An unexpected error occurred' };
        }
    };

    useEffect(() => {
        if (selectedTab) {
            getCryptoFeeSetting();
        }
    }, [selectedTab, profileTab]);

    const handleCloseModal = () => {
        setEditFeeModal(false);
        setEditAllFee('');
    };

    const handleEditAllFee = value => {
        if (!handleInputValidation(value)) {
            return;
        }
        setEditAllFee(value);
    };
    return (
        <>
            <div className="lg:pt-8 bg-white">
                <div className="flex items-center justify-between pt-4 lg:p-0 px-6 lg:px-0">
                    <h1 className="lg:text-lg text-base font-semibold">Fee&apos;s</h1>
                </div>
                <div className="flex items-center h-[36px] z-50 cursor-pointer border-b mt-6 py-5">
                    {cryptoTransactionLabels.map(item => (
                        <div
                            key={item}
                            className={`flex justify-center items-center p-2 text-[15px] font-medium text-[#70707B] ${
                                selectedTab.includes(item)
                                    ? 'border-b-2 border-[#18181B] !text-[#18181B]'
                                    : ''
                            }`}
                            onClick={() => setSelectedTab(item)}>
                            {item}
                        </div>
                    ))}
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex items-center h-[36px] gap-2 z-50 cursor-pointer mt-6 py-5">
                        {feeProfileLabels.map(item => (
                            <div
                                key={item}
                                className={`flex justify-center items-center p-2 text-[15px] font-medium text-[#70707B] ${
                                    profileTab === item
                                        ? 'border-b-2 rounded-md border-[#18181B] bg-[#F4F4F5] !text-[#18181B]'
                                        : ''
                                }`}
                                onClick={() => setProfileTab(item)}>
                                {item}
                            </div>
                        ))}
                    </div>
                    {profileTab === 'Bespoke' && (
                        <Button
                            icon={
                                <img
                                    src={`${imageBaseUrl}${Edit}`}
                                    alt="edit"
                                    className="lg:w-4 w-6 h-6 lg:h-4"
                                />
                            }
                            onClick={() => setEditFeeModal(true)}
                            className="lg:border border-0 border-[#D1D1D6] mt-6 font-medium text-sm">
                            <span className="!hidden lg:!block">Edit all</span>
                        </Button>
                    )}
                </div>
                <div className="mt-6">
                    <Table
                        dataSource={
                            selectedTab === 'Exchange' && profileTab === 'Bespoke'
                                ? exchangeTableData
                                : data
                        }
                        columns={
                            selectedTab === 'Exchange' && profileTab === 'Bespoke'
                                ? exchangeColumns
                                : columns
                        }
                        loading={loading}
                        pagination={false}
                    />
                </div>
            </div>
            <CommonModal
                title="Edit All Fee’s"
                open={editFeeModal}
                handleClose={handleCloseModal}
                className={'common-mobile-view'}
                footerText={'Apply'}
                onFinish={() => editAllFee && handleUpdateFee({ editAll: true })}
                centered>
                <div className="flex justify-between items-center gap-10 mt-6">
                    <h1 className="text-base font-normal text-[#26272B]">Amount:</h1>
                    <Input
                        value={editAllFee}
                        onChange={e => handleEditAllFee(e.target.value)}
                        placeholder="Enter percentage fee"
                        className="rounded-3xl border-[#E4E4E7] h-[50px] max-w-[320px] bg-[#F4F4F5]"
                    />
                </div>
            </CommonModal>
        </>
    );
};

export default CryptoFeeTab;
