import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { DefrostConfirmation, Freeze, FreezeConfirmation } from '../../utils/Images';
import React from 'react';
import ThemeButton from '../common/ThemeButton';

export default function FreezeConfirmationModal({
    open,
    message,
    isFrozen,
    freezeAllCurrencies,
    handleClose,
}) {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    return (
        <div>
            <Modal
                open={open}
                width={'422px'}
                height={'330px'}
                destroyOnClose
                centered
                footer={false}
                className="transaction-result"
                closable={false}>
                <div
                    className={`px-5 flex flex-col gap-4 items-center pt-12 border-t-4 rounded-t-md border-t-[#2E90FA]`}>
                    <img
                        src={`${imageBaseUrl}${isFrozen ? DefrostConfirmation : FreezeConfirmation}`}
                        alt="icon"
                    />

                    <p className="font-semibold text-xl">
                        {isFrozen ? 'Unfreeze all' : 'Freeze all'}
                    </p>
                    <p className=" text-center text-gray-600 text-base mb-6">
                        {isFrozen
                            ? 'Are you sure you want to unfreeze all wallet balances?'
                            : 'Are you sure you want to freeze all wallet balances?'}
                    </p>
                </div>
                <hr />
                <div className="flex justify-evenly items-center p-6 bg-[#F4F4F5] gap-3 rounded-b-lg ">
                    <Button
                        className="px-5 py-[11px] rounded-full bg-[#E4E4E7] w-full h-full text-[#3F3F46] text-base font-medium"
                        onClick={() => {
                            handleClose();
                        }}>
                        Cancel
                    </Button>
                    <ThemeButton
                        text={isFrozen ? 'Unfreeze' : 'Freeze'}
                        type="primary"
                        shape="round"
                        style="font-medium bg-[#2E90FA] text-white"
                        action={freezeAllCurrencies}
                    />
                </div>
            </Modal>
        </div>
    );
}
