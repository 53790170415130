import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Popover } from 'antd';
import { EyeOutlined, PlusOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Download, Edit, Excel, PDF } from '../../utils/Images';
import CommonModal from '../modals/CommonModal';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import dayjs from 'dayjs';
import { FormDOB, FormSelect } from '../forms';
import { useForm } from 'antd/es/form/Form';
import ThemeButton from '../common/ThemeButton';
import ExcelJS from 'exceljs';
import { getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import { getExChangeAmount, getTransectionDetails } from '../../utils/constant';
import { disableFutureDates } from '../../utils/common';

const StatementTab = () => {
    const [openStatementModal, setOpenStatementModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [editRowData, setEditRowData] = useState(null);
    const [formData, setFormData] = useState({ currency: null, fromDate: null });
    const [data, setData] = useState([]);
    const balancesData = useSelector(state => state.account.balancesData);
    const cryptoBalancesData = useSelector(state => state.account.cryptoBalancesData);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const email = localStorage.getItem('email');
    const params = useParams();
    const [form] = useForm();
    const [openPopoverRow, setOpenPopoverRow] = useState(null);

    const fetchStatements = async () => {
        const body = {
            email: email,
            clientId: params.id,
        };

        try {
            const response = await apiRequest('/get-admin-client-statement', 'POST', body);

            if (response.success && response.data) {
                setData(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchStatements();
    }, [params.id]);

    const handleClose = () => {
        setOpenStatementModal(false);
        setEditModal(false);
        setFormData({});
    };

    const handleExpandRow = key => {
        setExpandedRow(expandedRow === key ? null : key);
    };

    const handleEdit = rowData => {
        setFormData({
            currency: rowData.currencyShortName,
            fromDate: dayjs(rowData.timePeriod.fromDate, 'YYYY-MM'),
        });
        form.setFieldsValue({
            currency: rowData.currencyShortName,
            fromDate: dayjs(rowData.timePeriod.fromDate, 'YYYY-MM'),
        });
        setEditRowData(rowData);
        setEditModal(true);
        setOpenStatementModal(true);
    };

    const downloadExcel = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const generateExcel = async data => {
        const { fromDate, endDate, currency, transactionDetails, openingBalance, closingBalance } =
            data;

        const startingDate = moment(fromDate).format('Do MMM');
        const endingDate = moment(endDate).format('Do MMM');
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('February 2022');

        const addFormattedRow = (
            rowData,
            mergeCellsRange = null,
            isBold = false,
            alignment = {},
        ) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, cell => {
                cell.alignment = { wrapText: true, ...alignment };
                if (isBold) cell.font = { bold: true };
            });
            if (mergeCellsRange && !worksheet.getCell(mergeCellsRange).isMerged) {
                worksheet.mergeCells(mergeCellsRange);
            }
            return row;
        };

        const setColumns = () => {
            worksheet.columns = [
                { key: 'Date', width: 15 },
                { key: 'Time', width: 15 },
                { key: 'Type', width: 20 },
                { key: 'Details', width: 25 },
                { key: 'Reference', width: 25 },
                { key: 'Amount', width: 25 },
                { key: 'Balance', width: 25 },
            ];
        };

        addFormattedRow([`${currency} Statement`], 'A1:G1', true, {
            vertical: 'top',
            horizontal: 'left',
        });
        addFormattedRow(
            ['Date', 'Time', 'Type', 'Details', 'Reference', 'Amount', 'Balance'],
            null,
            true,
        );
        if (transactionDetails.length === 0) {
            addFormattedRow([
                startingDate,
                '',
                '',
                'Statement Opening Balance',
                '',
                '',
                `${getCurrencySymbol(currency)} ${toFixedTrunc(openingBalance, 2)}`,
            ]);
            if (!worksheet.getCell('A3').isMerged) {
                worksheet.mergeCells('A3:G3');
            }
            const noDataCell = worksheet.getCell('A3');
            noDataCell.value = 'No transactions available for this date range';
            noDataCell.alignment = { horizontal: 'center', vertical: 'middle' };
            noDataCell.font = { bold: true };

            addFormattedRow([
                endingDate,
                '',
                '',
                'Statement Closing Balance',
                '',
                '',
                `${getCurrencySymbol(currency)} ${toFixedTrunc(closingBalance, 2)}`,
            ]);
        } else {
            addFormattedRow([
                startingDate,
                '',
                '',
                'Statement Opening Balance',
                '',
                '',
                `${getCurrencySymbol(currency)} ${toFixedTrunc(openingBalance, 2)}`,
            ]);
            transactionDetails
                .filter(row => row)
                .forEach(row => {
                    const feeText =
                        row.type === 'FX'
                            ? ''
                            : `Fee : ${getCurrencySymbol(currency)} ${toFixedTrunc(row?.transactionRef?.transactionFee || row?.transactionRef?.transferInFee) || 0.0}`;
                    const newRow = worksheet.addRow([
                        moment(row.creationDate).format('Do MMM'),
                        new Date(row.creationDate).toLocaleTimeString(),
                        `${row.type}${feeText ? `\n${feeText}` : ''}`,
                        getTransectionDetails(row),
                        row.transactionRef?.transactionDetails[0]?.reference || 'N/A',
                        getExChangeAmount(row),
                        `${getCurrencySymbol(currency)} ${toFixedTrunc(row.balanceAmount)}`,
                    ]);
                    newRow.eachCell({ includeEmpty: true }, cell => {
                        cell.alignment = { wrapText: true };
                    });
                });

            addFormattedRow([
                endingDate,
                '',
                '',
                'Statement Closing Balance',
                '',
                '',
                `${getCurrencySymbol(currency)} ${toFixedTrunc(closingBalance, 2)}`,
            ]);
        }
        setColumns();
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        downloadExcel(blob, 'Account_Statement.xlsx');
    };

    const handlePopoverVisibility = (rowId, open) => {
        if (open) {
            setOpenPopoverRow(rowId);
        } else {
            setOpenPopoverRow(null);
        }
    };

    const viewStatement = async (rowData, downloadType = 'pdf') => {
        setOpenPopoverRow(null);
        try {
            const payload = {
                statementId: rowData._id,
                fromDate: rowData.timePeriod.fromDate,
                endDate: rowData.timePeriod.endDate,
                clientId: rowData.clientId,
                type: rowData.currencyType,
                currency: rowData.currency,
                email: localStorage.getItem('email'),
                downloadType,
            };
            const response = await apiRequest('/generate-statement-pdf', 'POST', payload);

            if (response.success) {
                if (response.data) {
                    if (downloadType === 'pdf') {
                        const pdfData = response.data;
                        const newTab = window.open();
                        newTab.document.write(pdfData);
                    } else {
                        await generateExcel(response.data);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const createStatement = async () => {
        const body = {
            email: email,
            clientId: params.id,
            ...formData,
        };

        try {
            const response = await apiRequest('/generate-admin-client-statement', 'POST', body);
            if (response.success) {
                handleClose();
                fetchStatements();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateStatement = async () => {
        const body = {
            email: email,
            clientId: params.id,
            statementId: editRowData._id,
            endDate: editRowData.timePeriod.endDate,
            ...formData,
        };

        try {
            const response = await apiRequest('/update-admin-client-statement', 'POST', body);
            if (response.success) {
                handleClose();
                fetchStatements();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const ExpandedRow = ({ record }) => {
        return (
            <div className="p-3 bg-[#FCFCFC] border rounded-lg">
                {[
                    // { label: 'Statement name', value: record.name },
                    {
                        label: 'Statement Date',
                        value: moment(record.date).format('D.MM.YY HH:mm') || '-',
                    },
                    { label: 'Statement type', value: record.currencyShortName || '-' },
                    { label: 'Sent Amount', value: record.openingBalance || 0 },
                    { label: 'Received Amount', value: record.closingBalance || 0 },
                ].map(({ label, value }) => (
                    <p
                        key={label}
                        className="flex justify-between items-center font-normal text-xs text-darkGray pb-3">
                        <span>{label}:</span> {value}
                    </p>
                ))}
                <Divider type="horizontal" className="my-3" />
                <div className="flex items-center gap-[6px]">
                    <Button
                        className="py-[10px] px-3 text-[13px]"
                        onClick={() => viewStatement(record)}
                        icon={<EyeOutlined />}>
                        View report
                    </Button>
                    <Popover
                        content={generatePopoverContent(record)}
                        trigger="click"
                        open={openPopoverRow === record._id}
                        onOpenChange={open => handlePopoverVisibility(record._id, open)}>
                        <Button
                            className="py-[10px] px-3 text-[13px]"
                            icon={
                                <img
                                    src={`${imageBaseUrl}${Download}`}
                                    alt="download"
                                    className="w-4 h-4 inline"
                                />
                            }>
                            Download
                        </Button>
                    </Popover>
                    <Button
                        className="py-[10px] px-3 text-[13px]"
                        onClick={() => handleEdit(record)}
                        icon={
                            <img
                                src={`${imageBaseUrl}${Edit}`}
                                alt="edit"
                                className="w-4 h-4 inline"
                            />
                        }>
                        Edit
                    </Button>
                </div>
            </div>
        );
    };

    const generatePopoverContent = row => (
        <div className="p-2 rounded-lg">
            <button
                className="flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C]"
                onClick={() => viewStatement(row)}
                onTouchStart={() => viewStatement(row)}>
                <img src={`${imageBaseUrl}${PDF}`} className="w-6 h-6" alt="download" />
                PDF file
            </button>
            <button
                className="flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C] mt-2"
                onClick={() => viewStatement(row, 'excel')}
                onTouchStart={() => viewStatement(row, 'excel')}>
                <img src={`${imageBaseUrl}${Excel}`} className="w-6 h-6" alt="download" />
                Excel file
            </button>
        </div>
    );

    return (
        <div className="lg:pt-8 bg-white">
            <div className="flex items-center justify-between pt-4 lg:p-0 px-6 lg:px-0">
                <h1 className="text-base font-semibold">Statements</h1>
                <div className="font-medium text-sm">
                    <ThemeButton
                        type="primary"
                        style="hidden lg:inline-flex"
                        text="Add New Statement"
                        icon={<PlusOutlined />}
                        action={() => setOpenStatementModal(true)}
                    />
                    <Button
                        className="p-2 h-full lg:hidden"
                        icon={<PlusOutlined />}
                        onClick={() => setOpenStatementModal(true)}
                        type="primary">
                        Add New
                    </Button>
                </div>
            </div>
            <Divider className="lg:my-6 my-3" />
            <div className="hidden lg:block">
                {data.map((item, index) => (
                    <div
                        className="grid grid-cols-5 items-center justify-between border-b border-[#E4E4E7] pb-3 w-full"
                        key={index}>
                        <p className="text-sm font-normal text-darkGray">
                            {moment(item.createdAt).format('D.MM.YY HH:mm') || '-'}
                        </p>
                        <p className="text-sm font-normal text-darkGray">
                            {item.currencyShortName || '-'}
                        </p>
                        <p className="text-sm font-normal text-darkGray">
                            {item.openingBalance || '0'}
                        </p>
                        <p className="text-sm font-normal text-darkGray">
                            {item.closingBalance || '0'}
                        </p>
                        <div className="flex items-center gap-2">
                            <Button
                                className="border-0"
                                icon={<EyeOutlined />}
                                onClick={() => viewStatement(item)}></Button>
                            <Popover
                                content={generatePopoverContent(item)}
                                trigger="click"
                                open={openPopoverRow === item._id}
                                onOpenChange={open => handlePopoverVisibility(item._id, open)}>
                                <Button
                                    className="border-0"
                                    icon={<img src={`${imageBaseUrl}${Download}`} alt="download" />}
                                />
                            </Popover>
                            <Button
                                className="border-0"
                                icon={<img src={`${imageBaseUrl}${Edit}`} alt="edit" />}
                                onClick={() => handleEdit(item)}></Button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="block lg:hidden">
                {data.map((item, key) => {
                    const isExpanded = expandedRow === item._id;
                    return (
                        <div key={key} className="bg-white border-b border-[#E4E4E7]">
                            <div
                                className="flex justify-between items-center p-3 cursor-pointer"
                                onClick={() => handleExpandRow(item._id)}>
                                <div className="flex gap-2 items-center">
                                    {/* <span className="bg-[#12B76A] inline-block h-2 w-2 rounded-full"></span> */}
                                    <div>
                                        <h1 className="text-sm font-medium pb-1">
                                            {item.currencyShortName}
                                        </h1>
                                        <p className="font-normal text-xs text-darkGray">
                                            {moment(item.createdAt).format('D.MM.YY HH:mm')}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <div className="">
                                        {isExpanded ? (
                                            <UpOutlined width={10} height={10} />
                                        ) : (
                                            <DownOutlined width={10} height={10} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isExpanded && (
                                <div className="p-3">
                                    <ExpandedRow record={item} />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <CommonModal
                title={editModal ? 'Edit Statement' : 'Create New Statement'}
                open={openStatementModal}
                handleClose={handleClose}
                className={'common-mobile-view'}
                onFinish={!editModal ? createStatement : updateStatement}
                footerText={editModal ? 'Save' : 'Generate'}
                form={form}>
                <Form
                    labelCol={{ span: 7 }}
                    layout="horizontal"
                    className="mt-10 modal-form"
                    colon={false}
                    form={form}>
                    <FormDOB
                        picker="month"
                        label="Statement time period:"
                        name="fromDate"
                        placeholder="YYYY-MM"
                        format="YYYY-MM"
                        defaultValue={formData.fromDate}
                        onChange={(_, dateString) =>
                            setFormData({ ...formData, fromDate: dateString })
                        }
                        disabledDate={disableFutureDates}
                    />
                    <FormSelect
                        label="Currency:"
                        name="currency"
                        placeholder="Select an option"
                        defaultValue={formData.currency}
                        onChange={value => setFormData({ ...formData, currency: value })}
                        options={([...balancesData, ...cryptoBalancesData] || []).map(data => ({
                            value: data.currencyShortName,
                            label: (
                                <div className="flex items-center">
                                    <img
                                        data-e2e={data.currencyShortName}
                                        src={`${imageBaseUrl}${data.currencyIcon}`}
                                        alt={data.currencyShortName}
                                        className="w-5 mr-[20px]"
                                    />
                                    {data.currencyShortName}
                                </div>
                            ),
                        }))}
                    />
                </Form>
            </CommonModal>
        </div>
    );
};

export default StatementTab;
