import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/style.css';
import AppRoutes from './routes/routes';
import { fetchIpAddress } from './utils/common';
import { useCommonFunctions } from './utils/common';

const App = () => {
    const { getUnreadNotificationCount } = useCommonFunctions();

    useEffect(() => {
        fetchIpAddress();
        getUnreadNotificationCount();
        const intervalId = window.setInterval(
            () => {
                getUnreadNotificationCount();
            },
            10 * 60 * 1000,
        );
        return () => window.clearInterval(intervalId);
    }, []);

    return (
        <Router>
            <AppRoutes />
        </Router>
    );
};

export default App;
