import { useState } from 'react';
import { Form, DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import MultiSelect from '../common/MultiSelect';
import { disableFutureDates } from '../../utils/common';
import moment from 'moment';
import DrawerFilters from '../DrawerFilters';
import ThemeButton from '../common/ThemeButton';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';

export default function NotificationsFilters({
    showAdditionalInfo,
    setFilters,
    filters,
    open,
    setOpen,
    setPage,
    filtersForm,
    setFiltersForm,
}) {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const { RangePicker } = DatePicker;
    const dateFormat = 'DD.MM.YYYY';

    const priorityOptions = [
        { label: 'High', value: 'high' },
        { label: 'Medium', value: 'medium' },
        { label: 'Low', value: 'low' },
    ];

    const statusOptions = [
        { label: 'Read', value: 'read' },
        { label: 'Unread', value: 'unread' },
    ];

    const handleDateRange = range => {
        let [fromDate, toDate] = range;
        setFilters(prevState => ({
            ...prevState,
            fromDate,
            toDate,
        }));
        setPage(1);
    };
    const handleDateRangeMobile = range => {
        let [fromDate, toDate] = range;
        setFiltersForm(prevState => ({
            ...prevState,
            fromDate,
            toDate,
        }));
    };
    const handleCloseDrawer = () => {
        setOpen(false);
    };

    const handleResetValue = () => {
        setFilters({});
        setFiltersForm({});
        handleCloseDrawer();
    };

    const handleSearch = () => {
        setFilters(filtersForm);
        setPage(1);
    };

    function NotificationFilterButtons() {
        return (
            <div className="py-[8px] sm:py-[16px] grid grid-cols-[auto_1fr] lg:mt-6 sm:flex sm:justify-end sm:items-center gap-2">
                <ThemeButton
                    icon={<RedoOutlined />}
                    action={handleResetValue}
                    text="Reset"
                    autoWidth={true}
                    className="reset-filter-btn"
                />
                <ThemeButton
                    icon={<SearchOutlined />}
                    type="primary"
                    autoWidth={true}
                    action={() => {
                        handleSearch();
                        handleCloseDrawer();
                    }}
                    text="Search"
                />
            </div>
        );
    }

    return (
        <div>
            <div className="hidden lg:block">
                <Form>
                    <div className={`notif__form ${showAdditionalInfo ? 'has-details' : ''}`}>
                        <Form.Item name="priority">
                            <p className="text-darkGray mb-[4px]">Priority</p>
                            <MultiSelect
                                options={priorityOptions}
                                placeholder="Select an option"
                                name="priority"
                                formValue={filters}
                                onChange={selectedValues => {
                                    setFilters({
                                        ...filters,
                                        priority: selectedValues,
                                    });
                                    setPage(1);
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="status">
                            <p className="text-darkGray mb-[4px]">Status</p>
                            <MultiSelect
                                options={statusOptions}
                                placeholder="Select an option"
                                name="status"
                                formValue={filters}
                                onChange={selectedValues => {
                                    setFilters({
                                        ...filters,
                                        status: selectedValues,
                                    });
                                    setPage(1);
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="dateCreated">
                            <p className="text-darkGray mb-[4px]">Date</p>
                            <RangePicker
                                format={dateFormat}
                                value={[filters?.fromDate, filters?.toDate]}
                                variant="outlined"
                                suffixIcon={
                                    <img
                                        alt="calendar"
                                        src={`${imageBaseUrl}/adminUi/calenderIcon.svg`}
                                        className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                                    />
                                }
                                className={`p-[6px] w-full h-full filter-range-picker-selected`}
                                popupClassName="calender-range-picker"
                                disabledDate={disableFutureDates}
                                onChange={handleDateRange}
                                allowClear={false}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>

            {/* Mobile drawer */}
            <DrawerFilters
                title="Filters"
                onClose={handleCloseDrawer}
                open={open}
                footer={<NotificationFilterButtons />}>
                <Form.Item name="priority">
                    <p className="text-darkGray mb-[4px]">Priority</p>
                    <MultiSelect
                        options={priorityOptions}
                        placeholder="Select an option"
                        name="priority"
                        formValue={filtersForm}
                        onChange={selectedValues =>
                            setFiltersForm({
                                ...filtersForm,
                                priority: selectedValues,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item name="status">
                    <p className="text-darkGray mb-[4px]">Status</p>
                    <MultiSelect
                        options={statusOptions}
                        placeholder="Select an option"
                        name="status"
                        formValue={filtersForm}
                        onChange={selectedValues =>
                            setFiltersForm({
                                ...filtersForm,
                                status: selectedValues,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item name="dateCreated">
                    <p className="text-darkGray mb-[4px]">Date</p>
                    <RangePicker
                        format={dateFormat}
                        value={[filtersForm?.fromDate, filtersForm?.toDate]}
                        variant="outlined"
                        suffixIcon={
                            <img
                                alt="calendar"
                                src={`${imageBaseUrl}/adminUi/calenderIcon.svg`}
                                className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                            />
                        }
                        className={`p-[6px] w-full h-full filter-range-picker-selected`}
                        popupClassName="calender-range-picker"
                        disabledDate={disableFutureDates}
                        onChange={handleDateRangeMobile}
                        allowClear={false}
                    />
                </Form.Item>
            </DrawerFilters>
        </div>
    );
}
