import { useState, useEffect } from 'react';
import { Tag } from 'antd';
import moment from 'moment';

export default function NotificationsFilterTags({
    filters,
    setFilters,
    clearQueryRender,
    color,
    setFiltersForm,
}) {
    const [filterQuery, setFilterQuery] = useState([]);

    useEffect(() => {
        let filtersArr2 = [];
        let fromDate, toDate;

        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                const getType = () => {
                    switch (key) {
                        case 'priority':
                            return 'Priority';
                        case 'status':
                            return 'Status';
                        case 'fromDate':
                        case 'toDate':
                            return 'Date';
                        default:
                            return undefined;
                    }
                };
                const getValue = () => {
                    if (key === 'fromDate' || key === 'toDate') {
                        return value;
                    } else {
                        const formatValue = value => {
                            if (typeof value === 'string') {
                                return value;
                            } else if (Array.isArray(value)) {
                                return value.join(', ');
                            } else {
                                return '';
                            }
                        };
                        return formatValue(value);
                    }
                };
                //Handle Date range
                if (key === 'fromDate') {
                    fromDate = getValue();
                } else if (key === 'toDate') {
                    toDate = getValue();
                } else if (value && value.length > 0) {
                    const obj = { label: getType(), value: getValue(), key };
                    if (value) {
                        filtersArr2.push(obj);
                    }
                }
            }
            if (fromDate && toDate) {
                filtersArr2.push({
                    label: 'Date',
                    value: `${moment(fromDate?.$d).format('DD.MM.YYYY')} - ${moment(toDate?.$d).format('DD.MM.YYYY')}`,
                });
            }

            setFilterQuery(filtersArr2);
        }
    }, [filters]);

    function handleTagClose(selectedFilter) {
        if (selectedFilter?.label != 'Date') {
            const { [selectedFilter?.key]: _, ...newData } = filters;
            setFilters(newData);
            setFiltersForm && setFiltersForm(newData);
        } else {
            setFilters(prevState => {
                const newState = { ...prevState };
                delete newState.fromDate;
                delete newState.toDate;
                return newState;
            });
            setFiltersForm &&
                setFiltersForm(prevState => {
                    const newState = { ...prevState };
                    delete newState.fromDate;
                    delete newState.toDate;
                    return newState;
                });
        }
    }

    return (
        <div className="filter-tags my-[12px] lg:mt-[24px] lg:mb-0">
            {filterQuery.map((filter, key) => (
                <Tag
                    bordered={false}
                    closable
                    key={key}
                    className={`text-sm font-medium ${color && '!bg-white'}`}
                    onClose={() => handleTagClose(filter)}>
                    <span>
                        {filter.label}: {filter?.value}
                    </span>
                </Tag>
            ))}

            {filterQuery.length > 0 && (
                <div
                    className="text-[#1570EF] text-sm font-medium cursor-pointer pl-[7px]"
                    onClick={clearQueryRender}>
                    Reset all ({filterQuery.length})
                </div>
            )}
        </div>
    );
}
